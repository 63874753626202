import React, { useState, useEffect } from 'react';
import TitleLabel from '../../CommonComponent/TitleLabel';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useSelector } from "react-redux";
import { getSchoolDataAPIURL, saveTransferStudentDateAPIURL } from '../../Administration/Services/TransferStudentsAPIURL';
import { getStudentList } from '../../CommonServices/StudentRegistrationAPIURL';
import Loader from '../../CommonComponent/Loader';
import { getClassDivisionURL } from '../../Administration/Services/PramotShiftStudentsAPIURL';
import { BaseURLSection } from '../../CommonServices/InstituteMasterAPIURL';
import { GetStudentLcData } from '../../CommonServices/LeavingCertificateAPIURL';
import { newRegCode } from '../../CommonServices/StudentRegistrationAPIURL';
import moment from 'moment';
import girslImg from '../../assets/dashboard/girl.png'
import boyImg from '../../assets/dashboard/boy.png'
import team from '../../assets/dashboard/team.png';
import teamwork from '../../assets/dashboard/teamwork.png';
import lecture from '../../assets/dashboard/lecture.png';
import graduates from '../../assets/dashboard/graduates.png';
import '../../Dashboard.css';
import { getCardData, getStudCategorySummaryAPIURL, getStudentCategorySummarySansthaAPIURL } from '../../CommonServices/DashboardAPIURL';
import { getCurrentStudentCardData } from '../Services/StudentProfileAPIURL';
import CategoryIcon from '@mui/icons-material/Category';
import { Bar } from 'react-chartjs-2';
import { Breadcrumbs } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';

//for link style
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[900];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const students = [
    {
        id: 1,
        name: "John Doe",
        regNo: "123456",
        class: "10th",
        status: "Enrolled",
        image: `${girslImg}`, // Replace with actual image URL
    },
    {
        id: 2,
        name: "Jane Smith",
        regNo: "789012",
        class: "9th",
        status: "Left",
        image: `${boyImg}`, // Replace with actual image URL
    },
    // Add more student objects as needed
];

const StudentProfile = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Profile";

    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [schoolData, setSchoolData] = useState([]);
    const [schoolObj, setSchoolObj] = useState(null);
    const [sectionData, setSectionData] = useState([]);
    const [sectionObj, setSectionObj] = useState(null);
    const [studentData, setStudentData] = useState([]);
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [studentObj, setStudentObj] = useState(null);
    const [newRegNo, setNewRegNo] = useState("");
    const [dateValue, setDateValue] = useState(null);

    const [allSummaryData, setAllSummaryData] = useState([]);
    const [currentSummaryData, setCurrentSummaryData] = useState([]);
    const [studCategoryData, setStudCategoryData] = useState([]);

    //console.log(studentData.length)

    useEffect(() => {
        getData();
        // getNewRegCode();
    }, [authUser]);

    const getData = async () => {
        console.log(getSchoolDataAPIURL)
        await axios(`${getSchoolDataAPIURL}`)
            .then(res => {
                setSchoolData(res.data);
                //console.log("getSchoolDataAPIURL" + JSON.stringify(res.data))
            })
        await axios(`${BaseURLSection}`)
            .then(res => {
                setSectionData(res.data);
                //console.log("BaseURLSection" + JSON.stringify(res.data))
            })


    }


    //Student Entry required data
    const [countData, setCountData] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);

    // useEffect(() => {
    //     getAllStudentList();
    // }, [numCal])

    const getAllStudentList = async () => {
        setLoaderOption(true);
        // if (sectionObj != null || schoolObj != null) {
        //     await axios.get(`${GetStudentLcData}?sid=${sectionObj.id}&bid=${schoolObj.id}&limit=${limits}&offset=${offset}`)
        //         .then((res) => {
        //             if (res.data !== null) {
        //                 const tempData = res.data;
        //                 //console.log(tempData.length)
        //                 setStudentData(studentData.concat(tempData));
        //                 //setFilteredData(filteredData.concat(tempData));
        //                 //console.log("Data : " + JSON.stringify(data))
        //                 //console.log("____________________________________________")
        //                 if (tempData.length === limits) {
        //                     setOffset(offset + limits);
        //                     setNumCall(numCal + 1);
        //                     //console.log("Offset ===============" + offset);
        //                 }
        //             }
        //         })
        // }

        await axios(`${getCardData}?acyr=${authUser.sessionYear}&sid=${sectionObj.id}&bid=${schoolObj.id}`)
            .then(res => {
                setAllSummaryData(res.data);
            })

        await axios(`${getCurrentStudentCardData}?sid=${sectionObj.id}&bid=${schoolObj.id}`)
            .then(res => {
                setCurrentSummaryData(res.data);
            })

        await axios(`${getStudCategorySummaryAPIURL}?acyr=${authUser.sessionYear}&sid=${sectionObj.id}&bid=${schoolObj.id}`)
            .then(res => {
                // console.log(JSON.stringify(res.data));
                setStudCategoryData(res.data);
                setLoaderOption(false);
            })
    }



    const onSubmit = (e) => {
        e.preventDefault();
        getAllStudentList();
    }

    //Category bar chart
    // Group data by classdiv and catMinEn
    const categoryGroupedData = {};

    // Handle undefined or null values
    studCategoryData.forEach((item) => {
        if (!item) return;

        const classdiv = (authUser.branchMedium == 1) ? `${item.classMr} - ${item.divMr}` : `${item.classEn} - ${item.divEn}`;

        if (!categoryGroupedData[classdiv]) {
            categoryGroupedData[classdiv] = {};
        }

        const catMinEn = (authUser.branchMedium == 1) ? item.catMinMr : item.catMinEn || 'Others';

        if (!categoryGroupedData[classdiv][catMinEn]) {
            categoryGroupedData[classdiv][catMinEn] = {
                boysCount: 0,
                girlsCount: 0,
            };
        }

        categoryGroupedData[classdiv][catMinEn].boysCount += item.boysCount || 0;
        categoryGroupedData[classdiv][catMinEn].girlsCount += item.girlsCount || 0;
    });

    // Prepare data for chart
    const categories = Array.from(
        new Set(studCategoryData.map((item) => (authUser.branchMedium == 1) ? item.catMinMr : item.catMinEn || 'Others'))
    );

    // Define fixed colors for each category
    const fixedColorsCategory = [
        '#FF6B5B',
        '#59FF5B',
        '#69DDFF',
        '#FDD759',
        '#7773FE',
        '#FF66BC',
        '#D05FFE',
        '#FC8B52',
        '#807C78'
        // Add more fixed colors for other categories
    ];

    const getRandomColor = (usedColors) => {
        let color;
        do {
            const hue = Math.floor(Math.random() * 360);
            const lightness = Math.floor(Math.random() * 20) + 70; // Adjust the range to get lighter colors
            color = `hsl(${hue}, 100%, ${lightness}%)`;
        } while (usedColors.has(color));
        usedColors.add(color);
        return color;
    };

    const providedDataLengthCategory = studCategoryData.length;
    const maxBarThicknessLargeCategory = 10; // Define your maximum desired bar thickness for large datasets
    const maxBarThicknessSmallCategory = 20; // Define your maximum desired bar thickness for small datasets
    // console.log(providedDataLengthCategory)
    const maxBarThicknessCategory = providedDataLengthCategory <= 40 ? maxBarThicknessSmallCategory : maxBarThicknessLargeCategory;

    const categoryChartData = {
        labels: Object.keys(categoryGroupedData), // classdiv
        datasets: categories.map((category, index) => {
            const barThickness = maxBarThicknessCategory;
            return {
                label: category,
                data: Object.values(categoryGroupedData).map((classData) =>
                    (classData[category] ? classData[category].boysCount + classData[category].girlsCount : 0)
                ),
                backgroundColor: index < fixedColorsCategory.length ? fixedColorsCategory[index] : getRandomColor(new Set(fixedColorsCategory)),
                barThickness: barThickness,
                // categoryPercentage: 0.9, // Adjust this value to increase/decrease the gap
                barPercentage: 1, // Adjust this value to increase/decrease the gap
            }
        }),
    };

    // Chart options with switched x and y scales for a horizontal bar chart
    const categoryOptions = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
    };

    useEffect(() => {
        // Cleanup localStorage on component unmount
        return () => localStorage.removeItem('chartColors');
    }, []);



    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className="row">
                            <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >From School :</label>
                            <div className='col-sm-5'>
                                <Autocomplete
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    onChange={(e, newValue) => {
                                        setSchoolObj(newValue);
                                        //getStudentData(newValue.id);
                                        setStudentObj(null);
                                        setClassDivObj(null);
                                        setStudentData([]);
                                    }}
                                    value={schoolObj}
                                    options={schoolData}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Select School" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >From Department :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    onChange={(e, newValue) => {
                                        setSectionObj(newValue);
                                        setClassDivObj(null);
                                        setStudentObj(null);
                                        setStudentData([]);
                                    }}
                                    value={sectionObj}
                                    options={sectionData}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Select Department" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <button style={{ marginTop: "14px", backgroundColor: "#46CC51", color: "#fff" }} className='btn btn-sm' type='submit'>Get Data</button>
                            </div>
                        </div>
                    </form>
                    <hr />

                    {(() => {
                        if (allSummaryData.length != 0 && currentSummaryData.length != 0) {
                            return (
                                <>
                                    <div className="container mt-4">
                                        <div className="row">
                                            {/* First Div */}
                                            <div className="col-md-6">
                                                <div className="p-3 border bg-light">
                                                    <h5>All Summary</h5>
                                                    <div className="row">
                                                        {/* Card 1 */}
                                                        <div className="col-md-6">
                                                            <div className="card mb-3 bg-c-pink">
                                                                <div className="d-flex align-items-center p-3">
                                                                    {/* Image Part */}
                                                                    <img src={graduates} className="f-left mt-2" style={{ width: "80px" }} />
                                                                    {/* Text Part */}
                                                                    <div className="ms-3">
                                                                        <h5 className="mb-0">Students</h5>
                                                                        <h6 className="mb-0 mt-4">{allSummaryData.totalStudents}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card bg-c-blue mb-3">
                                                                <div className="d-flex justify-content-between align-items-center p-3">
                                                                    {/* First Image Part */}
                                                                    <div className="text-center">
                                                                        <img src={girslImg} className="mb-2" style={{ width: "60px" }} />
                                                                        <h6 className="mb-0">{allSummaryData.totalGirls}</h6>
                                                                    </div>

                                                                    {/* Second Image Part */}
                                                                    <div className="text-center">
                                                                        <img src={boyImg} className="mb-2" style={{ width: "60px" }} />
                                                                        <h6 className="mb-0">{allSummaryData.totalBoys}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row my-2">
                                                        {/* Card 2 */}
                                                        <div className="col-md-6">
                                                            <div className="card bg-c-yellow mb-3">
                                                                <div className="d-flex align-items-center p-3">
                                                                    {/* Image Part */}
                                                                    <img src={team} className="f-left mt-2" style={{ width: "80px" }} />
                                                                    {/* Text Part */}
                                                                    <div className="ms-3">
                                                                        <h5 className="mb-0">Staff</h5>
                                                                        <h6 className="mb-0 mt-4">{allSummaryData.totalStaff}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card bg-c-green mb-3">
                                                                <div className="d-flex justify-content-between align-items-center p-3">
                                                                    {/* First Image Part */}
                                                                    <div className="text-center">
                                                                        <img src={lecture} className="mb-2" style={{ width: "60px" }} />
                                                                        <h6 className="mb-0">{allSummaryData.teachingStaff}</h6>
                                                                    </div>

                                                                    {/* Second Image Part */}
                                                                    <div className="text-center">
                                                                        <img src={teamwork} className="mb-2" style={{ width: "60px" }} />
                                                                        <h6 className="mb-0">{allSummaryData.nonTeachingStaff}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Second Div */}
                                            <div className="col-md-6">
                                                <div className="p-3 border bg-light">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className="mb-0">Current Summary</h5>
                                                        <Breadcrumbs aria-label="breadcrumb">
                                                            <StyledBreadcrumb
                                                                component="button"
                                                                onClick={() => {
                                                                    navigate(`/Home/ssd1103/${schoolObj.id}/${sectionObj.id}`, {
                                                                        state: {
                                                                            schoolNameMr: (schoolObj.medium == 1) ? schoolObj.schoolNameMr : schoolObj.schoolNameEn,
                                                                            sectionNameMr: (schoolObj.medium == 1) ? sectionObj.nameMr : sectionObj.nameEn,
                                                                            bMedium: schoolObj.medium
                                                                        }
                                                                    });
                                                                }}
                                                                style={{
                                                                    fontSize: "15px",
                                                                    fontWeight: "600",
                                                                    color: "#0044CB",
                                                                    textDecoration: "underline",
                                                                    padding: "2px"
                                                                }}
                                                                label="Show More"
                                                            />

                                                        </Breadcrumbs>
                                                    </div>
                                                    <div className="row mt-2">
                                                        {/* Card 1 */}
                                                        <div className="col-md-6">
                                                            <div className="card mb-3 bg-c-pink">
                                                                <div className="d-flex align-items-center p-3">
                                                                    {/* Image Part */}
                                                                    <img src={graduates} className="f-left mt-2" style={{ width: "80px" }} />
                                                                    {/* Text Part */}
                                                                    <div className="ms-3">
                                                                        <h5 className="mb-0">Students</h5>
                                                                        <h6 className="mb-0 mt-4">{currentSummaryData.totalStudents}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card bg-c-blue mb-3">
                                                                <div className="d-flex justify-content-between align-items-center p-3">
                                                                    {/* First Image Part */}
                                                                    <div className="text-center">
                                                                        <img src={girslImg} className="mb-2" style={{ width: "60px" }} />
                                                                        <h6 className="mb-0">{currentSummaryData.totalGirls}</h6>
                                                                    </div>

                                                                    {/* Second Image Part */}
                                                                    <div className="text-center">
                                                                        <img src={boyImg} className="mb-2" style={{ width: "60px" }} />
                                                                        <h6 className="mb-0">{currentSummaryData.totalBoys}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row my-2">
                                                        {/* Card 2 */}
                                                        <div className="col-md-6">
                                                            <div className="card bg-c-yellow mb-3">
                                                                <div className="d-flex align-items-center p-3">
                                                                    {/* Image Part */}
                                                                    <img src={team} className="f-left mt-2" style={{ width: "80px" }} />
                                                                    {/* Text Part */}
                                                                    <div className="ms-3">
                                                                        <h5 className="mb-0">Staff</h5>
                                                                        <h6 className="mb-0 mt-4">{currentSummaryData.totalStaff}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card bg-c-green mb-3">
                                                                <div className="d-flex justify-content-between align-items-center p-3">
                                                                    {/* First Image Part */}
                                                                    <div className="text-center">
                                                                        <img src={lecture} className="mb-2" style={{ width: "60px" }} />
                                                                        <h6 className="mb-0">{currentSummaryData.teachingStaff}</h6>
                                                                    </div>

                                                                    {/* Second Image Part */}
                                                                    <div className="text-center">
                                                                        <img src={teamwork} className="mb-2" style={{ width: "60px" }} />
                                                                        <h6 className="mb-0">{currentSummaryData.nonTeachingStaff}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className='row my-2'>
                                            <div className="col-sm-12">
                                                <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: '270px' }}>
                                                    {/* Header */}
                                                    <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                                                        <span><CategoryIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                                                        <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Student Category Summary</span>
                                                    </div>
                                                    {/* Body */}
                                                    {/* <div className='' style={{ height: '500px', overflowY: "scroll", margin: "4px 0px",width:'100%' }}> */}
                                                    <div className="chart-container" style={{ width: '100%', height: '500px' }}>

                                                        <Bar data={categoryChartData} options={categoryOptions} />
                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })()}



                </div>


            </>
        )
    }


}

export default StudentProfile
