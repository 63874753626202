import React, { useEffect, useState } from 'react'
import { getStudentListForProfileAPIURL, getStudentProfileDataAPIURL, serachStudentByNameAPIURL, serachStudentByRegNoAPIURL } from '../Services/StudentProfileAPIURL';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import TitleLabel from '../../CommonComponent/TitleLabel';
import studImg from '../../assets/dashboard/StudProfileImg.png'
import Loader from '../../CommonComponent/Loader';
import { imagePath } from '../../Administration/Services/StudentIdcardAPIURL';
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, TablePagination, Tabs, TextField, Tooltip } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import TableViewIcon from '@mui/icons-material/TableView';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';
import { ArrowBack } from '@mui/icons-material';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
}));

const StudentProfileInDetail = () => {
    const classes = useStyles();

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Profile";
    const { scId, sId } = useParams();
    const navigate = useNavigate();

    const location = useLocation();

    // Access paritoshikName from location state
    const scName = location.state?.schoolNameMr;
    const dName = location.state?.sectionNameMr;
    const bMedium = location.state?.bMedium;

    const [studentData, setStudentData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [loaderOption, setLoaderOption] = useState(false);
    const [option, setOption] = useState(1);
    const [searchedRegNo, setSearchedRegNo] = useState('');
    const [searchedNameFirst, setSearchedNameFirst] = useState('');
    const [searchedNameMiddle, setSearchedNameMiddle] = useState('');
    const [searchedNameLast, setSearchedNameLast] = useState('');
    const [language, setLanguage] = useState(2);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchTerm, setSearchTerm] = useState("")


    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const [tabValue, setTabValue] = useState(0); // Default tab is 0

    // Retrieve tabValue from localStorage on component mount
    useEffect(() => {
        const storedTab = localStorage.getItem('selectedTab');
        if (storedTab !== null) {
            setTabValue(parseInt(storedTab, 10)); // Set to stored value
        }
    }, []);

    // Handle tab change and store the value in localStorage
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        localStorage.setItem('selectedTab', newValue);
    };

    // Handle "Back" tab click and reset the tabValue
    const handleBackClick = () => {
        localStorage.setItem('selectedTab', 0); // Reset to default
        navigate('/Home/ss1103/');
    };


    const [isSearchActive, setIsSearchActive] = useState(false);
    // console.log(isSearchActive)

    // Example useEffect if you rely on `numCal`
    useEffect(() => {
        //if (!isSearchActive) {
        getData();
        //}
    }, [authUser]); // Dependency includes the search flag



    const getData = async () => {
        // if (offset === 0) {
        //     setLoaderOption(true);
        // }
        setLoaderOption(true);
        if (authUser.sessionYear != '') {
            // await axios(`${getStudentListForProfileAPIURL}?sid=${sId}&bid=${scId}&limit=${limits}&offset=${offset}&sessionYear=${authUser.sessionYear}&type=1`)
            //     .then(res => {
            //         if (res.data !== null) {
            //             const tempData = res.data;
            //             setStudentData(studentData.concat(tempData));
            //             setFilteredData(filteredData.concat(tempData));
            //             setLoaderOption(false);

            //             if (!isSearchActive && tempData.length === limits) {
            //                 setOffset(offset + limits);
            //                 setNumCall(numCal + 1);
            //             }
            //         }
            //     });
            // console.log(`${getStudentProfileDataAPIURL}?sid=${sId}&bid=${scId}&year=${authUser.sessionYear}`)
            // console.log(`${getStudentProfileDataAPIURL}?sid=${sId}&bid=${scId}&year=${authUser.sessionYear}`)
            await axios.get(`${getStudentProfileDataAPIURL}?sid=${sId}&bid=${scId}&year=${authUser.sessionYear}`)
                .then(res => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        setStudentData(tempData);
                        setFilteredData(tempData);
                        setLoaderOption(false);
                    }
                });
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setIsSearchActive(true); // Mark search as active
        setFilteredData([]);
        let filteredData = [];

        if (option == 1 && searchedRegNo.trim()) {
            // Search by registration number (exact match)
            // filteredData = studentData.filter(student => student.regCode == searchedRegNo.trim());
            axios(`${serachStudentByRegNoAPIURL}?regNo=${searchedRegNo}&sid=${sId}&bid=${scId}`)
                .then(res => {
                    if (res.data !== null) {
                        filteredData = [res.data];

                        // Log the filtered data for debugging
                        // console.log("Filtered Data: ", JSON.stringify(filteredData));

                        // Set filtered data state
                        setFilteredData(filteredData);

                    }
                });

        }
        else if (option == 2) {
            // Handle name search for all combinations
            console.log(`${serachStudentByNameAPIURL}?langId=${language}&fName=${searchedNameFirst}&mName=${searchedNameMiddle}&lName=${searchedNameLast}&sid=${sId}&bid=${scId}`)
            axios(`${serachStudentByNameAPIURL}?langId=${language}&fName=${searchedNameFirst}&mName=${searchedNameMiddle}&lName=${searchedNameLast}&sid=${sId}&bid=${scId}`)
                .then(res => {
                    if (res.data !== null) {
                        setFilteredData(res.data);
                    }
                });

            // if (language == 1) {
            //     // English search
            //     filteredData = studentData.filter(student =>
            //         (!searchedNameFirst.trim() || student.firstNameEn?.toLowerCase() == searchedNameFirst.trim().toLowerCase()) &&
            //         (!searchedNameMiddle.trim() || student.middleNameEn?.toLowerCase() == searchedNameMiddle.trim().toLowerCase()) &&
            //         (!searchedNameLast.trim() || student.lastNameEn?.toLowerCase() == searchedNameLast.trim().toLowerCase())
            //     );
            // } else if (language == 2) {
            //     // Marathi search
            //     filteredData = studentData.filter(student =>
            //         (!searchedNameFirst.trim() || student.firstNameMr?.toLowerCase() == searchedNameFirst.trim().toLowerCase()) &&
            //         (!searchedNameMiddle.trim() || student.middleNameMr?.toLowerCase() == searchedNameMiddle.trim().toLowerCase()) &&
            //         (!searchedNameLast.trim() || student.lastNameMr?.toLowerCase() == searchedNameLast.trim().toLowerCase())
            //     );
            // }


            // // Log the filtered data for debugging
            // console.log("Filtered Data: ", JSON.stringify(filteredData));

            // // Set filtered data state
            // setFilteredData(filteredData);
        }

    };


    const handleClearSearch = () => {
        setIsSearchActive(false); // Reset the search flag
        setSearchedRegNo('');
        setSearchedNameFirst('');
        setSearchedNameMiddle('');
        setSearchedNameLast('');
        //setOption(1);
        //setLanguage(1);
        setFilteredData(studentData); // Reset filtered data to the full student list
    };


    useEffect(() => {
        setFilteredData(
            studentData.filter((item) =>
                item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.currentClassNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.currentClassNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.currentStatusName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.birthDate.toString().toLowerCase().includes(searchTerm.toLowerCase())
            ))
    }, [searchTerm])


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <div className=''>
                        <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>School : <span style={{ color: '#fe3627', fontWeight: '600' }}>{scName}</span></span>
                    </div>
                    <div className=''>
                        <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>Department : <span style={{ color: '#fe3627', fontWeight: '600' }}>{dName}</span></span>
                    </div>

                    {/* <div className="row mt-2">
                        <div className="col-sm-4">
                            <button type='button' className="btn btn-sm btn-danger" onClick={() => navigate('/Home/ss1103/')}>Back</button>
                        </div>
                    </div> */}

                    <hr />
                    {/* <div className="container-fluid"> */}
                    {/* Row for Tabs */}
                    <div className="row">
                        {/* Tabs Section */}
                        <div className="col-12 mt-2 mb-2">
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="View Tabs"
                            >
                                <Tab
                                    icon={<TableViewIcon style={{ fontSize: '25px', color: '#154360' }} />}
                                    label=""
                                    style={{
                                        backgroundColor: '#ffebeb',
                                        border: '1px solid #f5b7b1',
                                        borderRadius: '8px',
                                        width: '50px',
                                        height: '50px',
                                        marginRight: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    value={0}
                                />
                                <Tab
                                    icon={<PersonIcon style={{ fontSize: '25px', color: '#154360' }} />}
                                    label=""
                                    style={{
                                        backgroundColor: '#ffebeb',
                                        border: '1px solid #f5b7b1',
                                        borderRadius: '8px',
                                        width: '50px',
                                        height: '50px',
                                        marginRight: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    value={1}
                                />

                                <Tab
                                    icon={<ArrowBack style={{ fontSize: '25px', color: '#154360' }} />}
                                    label=""
                                    style={{
                                        backgroundColor: '#ffebeb',
                                        border: '1px solid #f5b7b1',
                                        borderRadius: '8px',
                                        width: '50px',
                                        height: '50px',
                                        marginRight: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    onClick={handleBackClick}
                                    value={2}

                                />
                            </Tabs>
                        </div>

                        {/* Search Input Section */}
                        <div className="col-12 d-flex justify-content-end mt-2 mb-2">
                            <input
                                hidden={(tabValue === 0) ? false : true}
                                className="form-control form-control-sm"
                                type="text"
                                style={{
                                    width: "250px",
                                    border: "1px solid #C2C1C1",
                                }}
                                placeholder="Search Here"
                                onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                value={searchTerm}
                            />
                        </div>
                    </div>

                    {/* Table Section */}
                    <Box mt={2}>
                        {tabValue === 0 && (
                            <>
                                <div className="table-responsive mt-3">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Reg No</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Name</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Class</th>
                                                {/* <th style={{ fontSize: "14px", fontWeight: "600" }}>Birth Date</th> */}
                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Status</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regCode}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>
                                                            {bMedium == 1 ? item.studentNameMr : item.studentNameEn}
                                                        </td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                            {bMedium == 1
                                                                ? `${item.currentClassNameMr} ${item.currentDivNameMr}`
                                                                : `${item.currentClassNameEn} ${item.currentDivNameEn}`}
                                                        </td>
                                                        {/* <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                            {moment(item.birthDate).format("DD/MM/YYYY")}
                                                        </td> */}
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                            {item.currentStatus == 0 ? (
                                                                <span className="badge" style={{ backgroundColor: "#1EC40D", borderRadius: "10px", fontWeight: "600" }}>
                                                                    Enroll
                                                                </span>
                                                            ) : (
                                                                <span className="badge" style={{ backgroundColor: "#C91B1B", borderRadius: "10px", fontWeight: "600" }}>
                                                                    Left
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Tooltip title="View">
                                                                <span className='btn btn-sm mx-1 p-1' style={{ backgroundColor: "#F03B0A", color: "#fff" }}
                                                                // data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                //onClick={() => showEmployeeDetails(item.employeeId, 3)}
                                                                >
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <TablePagination
                                                    style={{ padding: "0px" }}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    count={studentData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onRowsPerPageChange={onRowsPerPageChange}
                                                    onPageChange={onPageChange}
                                                    labelRowsPerPage="Rows Per Page :"
                                                />
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </>
                        )}
                        {tabValue === 1 && (
                            <>
                                <form onSubmit={handleSearch}>
                                    <div className="row">
                                        <label className="col-sm-1 my-1 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Language:</label>
                                        <div className='col-sm-3'>
                                            <RadioGroup row aria-label="Language" margin="dense" name="customized-radios" value={language} onChange={(e) => setLanguage(parseInt(e.target.value))}>
                                                <FormControlLabel value={2} control={<Radio color='primary' />} label="English" />
                                                <FormControlLabel value={1} control={<Radio color='primary' />} label="Marathi" />
                                            </RadioGroup>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                        <div className='col-sm-3'>
                                            <Box>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                                                    <Select
                                                        size='small'
                                                        required
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        fullWidth
                                                        MenuProps={{ classes: { paper: classes.select } }}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select1"
                                                        value={option}
                                                        label="Option"
                                                        onChange={(e) => {
                                                            setOption(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                                    >
                                                        <MenuItem value={1}>Search By Reg No</MenuItem>
                                                        <MenuItem value={2}>Search By Name</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>

                                    {(() => {
                                        if (option == 1) {
                                            return (
                                                <>
                                                    <div className="row">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reg No :</label>
                                                        <div className='col-sm-3'>
                                                            <TextField
                                                                required={(option == 1) ? true : false}
                                                                fullWidth
                                                                margin='dense'
                                                                onChange={e => setSearchedRegNo(e.target.value)}
                                                                onKeyPress={(e) => (e.key == "Enter") ? searchedRegNo : ""}
                                                                type="number"
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Reg No"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                value={searchedRegNo}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Name :</label>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                required={(option === 2 && !searchedNameMiddle && !searchedNameLast)}
                                                                onKeyDown={(e) => (e.key === " " && searchedNameFirst.length === 0) ? e.preventDefault() : ""}
                                                                fullWidth
                                                                margin="dense"
                                                                value={searchedNameFirst}
                                                                onChange={(e) => setSearchedNameFirst(e.target.value)}
                                                                autoComplete="off"
                                                                size="small"
                                                                id="outlined-basic"
                                                                label="First Name"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                required={(option === 2 && !searchedNameFirst && !searchedNameLast)}
                                                                onKeyDown={(e) => (e.key === " " && searchedNameMiddle.length === 0) ? e.preventDefault() : ""}
                                                                fullWidth
                                                                margin="dense"
                                                                value={searchedNameMiddle}
                                                                onChange={(e) => setSearchedNameMiddle(e.target.value)}
                                                                autoComplete="off"
                                                                size="small"
                                                                id="outlined-basic"
                                                                label="Middle Name"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                required={(option === 2 && !searchedNameFirst && !searchedNameMiddle)}
                                                                onKeyDown={(e) => (e.key === " " && searchedNameLast.length === 0) ? e.preventDefault() : ""}
                                                                fullWidth
                                                                margin="dense"
                                                                value={searchedNameLast}
                                                                onChange={(e) => setSearchedNameLast(e.target.value)}
                                                                autoComplete="off"
                                                                size="small"
                                                                id="outlined-basic"
                                                                label="Last Name"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />
                                                        </div>
                                                    </div>

                                                </>
                                            )
                                        }
                                    })()}

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-1">
                                            <button type='submit' className='btn btn-sm btn-primary'>Search</button>
                                            <button type='button' onClick={handleClearSearch} className='btn btn-sm btn-danger ml-2'>Reset</button>
                                        </div>
                                    </div>
                                </form>

                                <div className="container mt-4">
                                    {/* <h3 className="text-center mb-4">Student Details</h3> */}
                                    <div className="row">
                                        {filteredData.map((student) => (
                                            <div className="col-md-4 col-xl-4" key={student.id}>
                                                <div className="card" style={{ backgroundColor: '#ebedef', borderRadius: "8px" }}>
                                                    <div className="card-body card-block">
                                                        <div className="d-flex">
                                                            <img
                                                                src={student.studentImg ? `${imagePath}${student.studentImg}` : studImg}
                                                                alt={student.studentNameMr || 'User'}
                                                                className="rounded-circle"
                                                                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                                onError={(e) => { e.target.src = studImg; }} // Fallback to user icon if image fails to load
                                                            />
                                                            <div className="ms-3">
                                                                <h5 className="mb-1">{(bMedium == 1) ? student.studentNameMr : student.studentNameEn}</h5>
                                                                <small className="text-muted">Reg. No: {student.regCode}</small>
                                                                <p className="mb-0">
                                                                    <small>Class: {(bMedium == 1) ? student.currentClassNameMr + " " + student.currentDivNameMr : student.currentClassNamEn + " " + student.currentDivNamEn}</small>
                                                                </p>
                                                                <span
                                                                    className={`badge ${student.currentStatusName === "Enroll"
                                                                        ? "bg-success"
                                                                        : "bg-danger"
                                                                        }`}
                                                                >
                                                                    {student.currentStatusName}
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="card-footer"
                                                        style={{
                                                            backgroundColor: `#d7dbdd`,
                                                            // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                                            height: "35px",
                                                            borderTopLeftRadius: "20px",
                                                            borderTopRightRadius: "20px",
                                                            textAlign: "center",
                                                            padding: "5px",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        <div>
                                                            <span
                                                                onClick={() => {
                                                                    navigate(`/Home/sp1103/${scId}/${sId}/${student.regCode}`, {
                                                                        state: {
                                                                            schoolNameMr: scName,
                                                                            sectionNameMr: dName,
                                                                            bMedium: bMedium,
                                                                            classId: student.currentClassId
                                                                        }
                                                                    });
                                                                }}
                                                                className="badge rounded-pill badge-text" style={{ color: '#2980b9', fontWeight: 'bold' }}>More Info</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            // <div key={student.id} className="col-md-6 col-lg-4 mb-4">
                                            //     <div
                                            //         className={`card p-3 d-flex flex-column ${student.currentStatusName === "Enroll"
                                            //             ? "border-success"
                                            //             : "border-danger"
                                            //             }`}
                                            //         style={{ backgroundColor: '#ebedef', borderRadius: "8px" }}
                                            //     >
                                            //         <div className="d-flex">
                                            //             <img
                                            //                 src={student.studentImg ? `${imagePath}${student.studentImg}` : studImg}
                                            //                 alt={student.studentNameMr || 'User'}
                                            //                 className="rounded-circle"
                                            //                 style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                            //                 onError={(e) => { e.target.src = studImg; }} // Fallback to user icon if image fails to load
                                            //             />
                                            //             <div className="ms-3">
                                            //                 <h5 className="mb-1">{student.studentNameMr}</h5>
                                            //                 <small className="text-muted">Reg. No: {student.regCode}</small>
                                            //                 <p className="mb-0">
                                            //                     <small>Class: {student.currentClassNameMr + " " + student.currentDivNameMr}</small>
                                            //                 </p>
                                            //                 <span
                                            //                     className={`badge ${student.currentStatusName === "Enroll"
                                            //                         ? "bg-success"
                                            //                         : "bg-danger"
                                            //                         }`}
                                            //                 >
                                            //                     {student.currentStatusName}
                                            //                 </span>
                                            //             </div>
                                            //         </div>

                                            //         {/* View More button */}
                                            //         <div className="mt-auto d-flex justify-content-center">
                                            //             <button
                                            //                 className="btn btn-primary"
                                            //                 style={{
                                            //                     width: "100%",
                                            //                     borderRadius: "15px 15px 0 0",
                                            //                     backgroundColor: "#154360",
                                            //                     color: "#fff",
                                            //                     fontWeight: "600",
                                            //                 }}
                                            //             >
                                            //                 View More
                                            //             </button>
                                            //         </div>
                                            //     </div>

                                            // </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </Box>
                </div>
            </>
        )
    }
}

export default StudentProfileInDetail
