import { BaseURL } from "../../CommonServices/APIURL";

//http://192.168.195.102:8081/institute-sss/institute/sss/dashboard/getCardDataCurrent?sid=3&bid=1
export const getCurrentStudentCardData = BaseURL + "dashboard/getCardDataCurrent";

//http://192.168.195.102:8081/institute-sss/institute/sss/administration/studentEntry/studentEntryListBySessionYear/?sid=3&bid=1&limit=200&offset=0&sessionYear=2024&type=3
export const getStudentListForProfileAPIURL = BaseURL + "administration/studentEntry/studentEntryListBySessionYear";


// http://192.168.195.102:8081/institute-sss/institute/sss/administration/studentEntry/getStudentProfileDataList?year=2024&sid=3&bid=1
export const getStudentProfileDataAPIURL = BaseURL + "administration/studentEntry/getStudentProfileDataList";

//http://192.168.195.102:8081/institute-sss/institute/sss/administration/studentEntry/getStudentProfileDataByRegNo?regNo=13005&sid=3&bid=1
export const serachStudentByRegNoAPIURL = BaseURL + "administration/studentEntry/getStudentProfileDataByRegNo";

//http://192.168.195.102:8081/institute-sss/institute/sss/administration/studentEntry/getStudentProfileDataByName?langId=2&fName=vedant&mName=&lName=&sid=3&bid=1
export const serachStudentByNameAPIURL = BaseURL + "administration/studentEntry/getStudentProfileDataByName";

//http://192.168.195.102:8081/institute-sss/institute/sss/administration/studentEntry/getStudentDataByRegNo?regNo=13005&sid=3&bid=1
export const getStudentDetailsByRegNoAPIURL = BaseURL + "administration/studentEntry/getStudentDataByRegNo";

//http://192.168.195.102:8081/institute-sss/institute/sss/academics/exam/getTermHealthAndObservationByRegNo?regNo=12892&year=2024&sid=3&bid=1
export const getStudentAcademicsDataAPIURL = BaseURL + "academics/exam/getTermHealthAndObservationByRegNo";

//http://192.168.195.102:8081/institute-sss/institute/sss/fee/getStudentFeeDetails?regNo=13400&classId=9&sessionYear=2024&sid=3&bid=1
export const getStudentFeeDataAPIURL = BaseURL + "fee/getStudentFeeDetails";
