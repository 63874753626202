import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { toast } from 'react-toastify';
import { getStudentDetailsByRegNoAPIURL, getStudentList, printAttendanceCertificate } from '../Services/AttendanceCertificateAPIURL';
import { GetYearAPIURL } from '../../CommonServices/LoginAPIURL';
import moment from 'moment';
import Loader from "../../CommonComponent/Loader";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel } from '@mui/material';
import { getClassWithDiv } from '../Services/StudentRollNoAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const AttendanceCertificate = () => {
    const titleId = "Attendance Certificate";

    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    const [loaderOption, setLoaderOption] = useState(false);

    const saveBtnStyle = "AttendCertificateSaveBtn btn btn-primary btn-sm";

    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [id, setId] = useState("");

    let todaysdate = new Date();
    const [tDate, setTdate] = useState(todaysdate);
    const [studentData, setStudentData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [yearId, setYearId] = useState([]);
    const [studentId, setStudentId] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [division, setDivision] = useState("");
    const [classData, setclassData] = useState("");
    const [dateCheck, setDateCheck] = useState("");
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);
    const [presentDaysPerc, setPresentDaysPers] = useState(0);
    const [percMark, setPersMark] = useState('');
    const [regNo, setRegNo] = useState('');
    const [studentName, setStudentName] = useState('');

    const [previousClassData, setPreviousClassData] = useState([]);
    const [previousClassObj, setPreviousClassObj] = useState(null);


    const HandlePresentDaysPersCheckbox = (e) => {
        setPresentDaysPers(e.target.checked);
        setPreviousClassObj(null);
        setPersMark('');
        if (e.target.checked == true) {
            setPresentDaysPers(1);
        }
        else {
            setPresentDaysPers(0);
        }
    }

    useEffect(() => {
        getAPIData();
    }, [authUser]);

    const getStudentDetails = async (regCode) => {
        // setLoaderOption(true);
        if (regCode != '') {
            await axios(`${getStudentDetailsByRegNoAPIURL}?regNo=${regCode}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn(`Student not found with reg No ${regCode}`)
                    }
                    else {
                        setStudentName((authUser.branchMedium == 1) ? res.data.firstNameMr + " " + res.data.middleNameMr + " " + res.data.lastNameMr : res.data.firstNameEn + " " + res.data.middleNameEn + " " + res.data.lastNameEn);
                        setclassData((authUser.branchMedium == 1) ? res.data.currentClassMr : res.data.currentClassEn);
                        setDivision((authUser.branchMedium == 1) ? res.data.currentDivisionMr : res.data.currentDivisionEn);
                        setLoaderOption(false);
                    }
                })
        }

    }

    const getAPIData = async () => {
        await axios(`${GetYearAPIURL}`)
            .then(res => {
                const year = (res.data).filter(e => e.yearLabel == authUser.yearLabel)
                setYearData(res.data);
                setYearId((year && year.length > 0) ? year[0].id : '')
            })
        if (authUser.deptId != null && authUser.branchId != null) {
            // await axios(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}`)
            //     .then(res => {
            //         console.log("student data length", res.data.length)
            //         setStudentData(res.data);
            //     })

            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setPreviousClassData(res.data);
                })

            if (offset === 0) {
                // setLoaderOption(true)
            }
            await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
                .then((res) => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        //console.log(tempData.length)
                        setStudentData(studentData.concat(tempData));
                        setLoaderOption(false)
                        //console.log("Data : " + JSON.stringify(data))
                        // console.log("____________________________________________")
                        if (tempData.length === limits) {
                            setOffset(offset + limits);
                            setNumCall(numCal + 1);
                            //console.log("Offset ===============" + offset);
                        }
                    }
                })
        }

        const maxDate = new Date(defaultMaxDate);
        maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
        if (tDate < defaultMinDate || tDate >= maxDate) {
            setDateCheck("Invalid date");
        } else {
            setDateCheck("");
        }
    }

    const printAtttendanceCertificate = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (moment(tDate).format("DD/MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date" && studentName != '' && classData != '' && division != '') {
            console.log(`${printAttendanceCertificate}?regNo=${regNo}&sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}&yearId=${yearId}&langId=${authUser.branchMedium}&date=${moment(tDate).format("DD/MM/YYYY")}&isManual=${presentDaysPerc}&percentage=${(presentDaysPerc == 0) ? 0 : percMark}&prevClassId=${(presentDaysPerc == 0) ? 0 : previousClassObj.classId}&prevDivId=${(presentDaysPerc == 0) ? 0 : previousClassObj.divisionId}`)
            await axios.get(`${printAttendanceCertificate}?regNo=${regNo}&sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}&yearId=${yearId}&langId=${authUser.branchMedium}&date=${moment(tDate).format("DD/MM/YYYY")}&isManual=${presentDaysPerc}&percentage=${(presentDaysPerc == 0) ? 0 : percMark}&prevClassId=${(presentDaysPerc == 0) ? 0 : previousClassObj.classId}&prevDivId=${(presentDaysPerc == 0) ? 0 : previousClassObj.divisionId}`)
                .then((res) => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false)
                        toast.warn("Studnet Data Not Found.")
                    }
                    else if (res.data != null) {
                        window.open(`${getExportedReportAPIURL}${res.data}`)
                        setLoaderOption(false)
                    }
                })
                .catch(error => {
                    console.log("Error : " + error)
                    setLoaderOption(false)
                })
            // setStudentObj(null);
            // setclassData("");
            // setDivision("");
        }
        else if (studentName == '' || classData == '' || division == '') {
            setLoaderOption(false);
            toast.warn("Student not found.")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const ClearData = () => {
        setRegNo('');
        setStudentName('');
        setclassData("");
        setDivision("");
        setPresentDaysPers(0);
        setPersMark('');
        setTdate(todaysdate);
        const year = yearData.filter(e => e.yearLabel == authUser.yearLabel)
        setYearId((year && year.length > 0) ? year[0].id : '')
    }

    const onSubmit = e => {
        e.preventDefault();
        printAtttendanceCertificate();
    };


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "480px" }}>
                    <form onSubmit={printAtttendanceCertificate}>

                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className="row">

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={tDate}
                                        minDate={defaultMinDate}
                                        maxDate={defaultMaxDate}
                                        onChange={(newValue) => {
                                            setTdate(newValue);
                                            const maxDate = new Date(defaultMaxDate);
                                            maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                            if (newValue < defaultMinDate || newValue >= maxDate) {
                                                setDateCheck("Invalid date");
                                            } else {
                                                setDateCheck("");
                                            }
                                        }}
                                        openTo="day"
                                        onOpen={() => setTdate(tDate || defaultMaxDate)}
                                        onKeyPress={(e) => (e.key == "Enter") ? tDate : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "12px" }}>Year :</label>
                            <div className='col-sm-3 mt-2'>
                                <FormControl fullWidth>
                                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Year</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth
                                        readOnly={false}
                                        margin='dense'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={yearId}
                                        defaultValue={[yearData[0]]}
                                        label="Select Year"
                                        onChange={(e) => setYearId(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? yearId : ""}
                                    >
                                        {yearData.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>{item.yearLabel}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>

                        </div>

                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Reg No :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Reg No"
                                    variant="outlined"
                                    margin='dense'
                                    value={regNo}
                                    onChange={(e) => setRegNo(e.target.value)}
                                    onBlur={(e) => getStudentDetails(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? regNo : ""}
                                    // inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className="col-sm-4">
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Student Name"
                                    variant="outlined"
                                    margin='dense'
                                    value={studentName}
                                    onChange={(e) => setStudentName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? studentName : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Class"
                                    variant="outlined"
                                    margin='dense'
                                    value={classData}
                                    onChange={(e) => setclassData(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? classData : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Division"
                                    variant="outlined"
                                    margin='dense'
                                    value={division}
                                    onChange={(e) => setDivision(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? division : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            {/* <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Student :</label> */}
                            <div className='col-sm-3'>
                                {/* <Autocomplete
                                    id="combo-box-demo"
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    loading={loading}
                                    options={studentData}
                                    readOnly={false}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setStudentObj(newValue);
                                        setclassData('');
                                        setDivision('');
                                        if (newValue != null) {
                                            setclassData((authUser.branchMedium == 1) ? newValue.currentClassMr : newValue.currentClassEn);
                                            setDivision((authUser.branchMedium == 1) ? newValue.currentDivisionMr : newValue.currentDivisionEn);
                                            setStudentId(newValue.regCode);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                    value={studentObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                    renderOption={(props, option) => (
                                        <div className='' {...props}>
                                            <div className=''>
                                                {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                            </div>
                                        </div>
                                    )}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                /> */}

                                {/* <Autocomplete
                                    id="combo-box-demo"
                                    //disableClearable
                                    //hidden={(languageId == null || languageId == "") ? true : false}
                                    options={studentData}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setclassData('');
                                        setDivision('');
                                        if (newValue != null) {
                                            setclassData((authUser.branchMedium == 1) ? newValue.currentClassMr : newValue.currentClassEn);
                                            setDivision((authUser.branchMedium == 1) ? newValue.currentDivisionMr : newValue.currentDivisionEn);
                                            setStudentId(newValue.regCode);
                                        }
                                        setStudentObj(newValue)
                                    }}
                                    value={studentObj}

                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + ((option.studentNameMr == "") ? option.studentNameEn : option.studentNameMr) : option.regCode + " - " + ((option.studentNameEn == "") ? option.studentNameMr : option.studentNameEn)}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                /> */}

                            </div>


                        </div>

                        <div className="row my-2">
                            <div className='col-sm-1 offset-sm-1 mt-2'>
                                <FormControlLabel control={<Checkbox checked={presentDaysPerc} onChange={HandlePresentDaysPersCheckbox} />} label="Manual" />
                            </div>

                            <label htmlFor="lastname3" hidden={(presentDaysPerc == 0) ? true : false} className="col-sm-1 ml-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Percentage:</label>
                            <div className='col-sm-3' hidden={(presentDaysPerc == 0) ? true : false}>
                                <TextField
                                    required={(presentDaysPerc == 1) ? true : false}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Pecrcentage"
                                    variant="outlined"
                                    margin='dense'
                                    value={percMark}
                                    onChange={(e) => setPersMark(e.target.value)}
                                    onKeyPress={(e) => (e.key === "Enter" ? percMark : "")}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{
                                        min: 1,
                                        max: 100,
                                        type: 'number' // Ensure only numeric input
                                    }}
                                />

                            </div>

                            <label htmlFor="lastname3" hidden={(presentDaysPerc == 0) ? true : false} className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Previous Class-Div :</label>
                            <div className='col-sm-3 mt-2' hidden={(presentDaysPerc == 0) ? true : false}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={previousClassData}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setPreviousClassObj(newValue);
                                        // console.log(newValue)
                                    }}
                                    value={previousClassObj}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Previous Class-Division" fullWidth autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(presentDaysPerc == 1) ? true : false} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-4 offset-sm-1">
                                <button type="submit" className={saveBtn}>Print</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form >
                </div >
            </>
        )
    }
}

export default AttendanceCertificate