import React, { useEffect, useState } from 'react';
import { getStudentAcademicsDataAPIURL, getStudentDetailsByRegNoAPIURL, getStudentFeeDataAPIURL, getStudentListForProfileAPIURL, getStudentProfileDataAPIURL, serachStudentByRegNoAPIURL } from '../Services/StudentProfileAPIURL';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import TitleLabel from '../../CommonComponent/TitleLabel';
import studImg from '../../assets/dashboard/StudProfileImg.png';
import Loader from '../../CommonComponent/Loader';
import { imagePath } from '../../Administration/Services/StudentIdcardAPIURL';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, TablePagination, Tabs, TextField, Tooltip } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Card, CardContent, Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
        },
        "& li": {
            fontSize: "14px",
            fontWeight: "550"
        },
    },
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    },
    tabsContainer: {
        display: 'flex',
        marginTop: '3px',
        borderRight: '1px solid #ddd', // Border for the entire tab container
    },
    imageCard: {
        // width: '200px',
        // marginRight: '20px',
        backgroundColor: '#aed6f1'
    },
    tabContent: {
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        flexGrow: 1,
        backgroundColor: '#d5dbdb'
    },
    tabPanel: {
        padding: '20px',
    },
    tab: {
        borderBottom: '1px solid #aeb6bf', // Border for each individual tab
        color: '#34495e'
    }
}));

const StudentCompleteDetails = () => {
    const classes = useStyles();

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Profile";
    const { scId, sId, rNo } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const scName = location.state?.schoolNameMr;
    const dName = location.state?.sectionNameMr;
    const bMedium = location.state?.bMedium;
    const cId = location.state?.classId;
    console.log(cId)
    const [studentData, setStudentData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loaderOption, setLoaderOption] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);
    const [studetAllDetails, setStudentAllDetails] = useState([]);
    const [studetAllAcademicsDetails, setStudentAllAcademicsDetails] = useState([]);
    const [studeFeeDetails, setStudenFeeDetails] = useState([]);


    const [selectedTab, setSelectedTab] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [studRollNo, setStudRolNo] = useState('');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    useEffect(() => {
        getData();
    }, [numCal, authUser]);

    const getData = async () => {
        // if (offset === 0) {
        //     setLoaderOption(true);
        // }
        setLoaderOption(true);
        if (authUser.sessionYear !== '') {
            // await axios(`${getStudentListForProfileAPIURL}?sid=${sId}&bid=${scId}&limit=${limits}&offset=${offset}&sessionYear=${authUser.sessionYear}&type=1`)
            //     .then((res) => {
            //         if (res.data !== null) {
            //             const tempData = res.data;
            //             const filteredByRegCode = tempData.filter(student => student.regCode == rNo);
            //             setStudentData(studentData.concat(filteredByRegCode));
            //             setFilteredData(filteredData.concat(filteredByRegCode));
            //             setLoaderOption(false);

            //             if (tempData.length === limits) {
            //                 setOffset(offset + limits);
            //                 setNumCall(numCal + 1);
            //             }
            //         }
            //     })
            //     .catch((error) => {
            //         console.error("Error fetching student data:", error);
            //         setLoaderOption(false);
            //     });

            // console.log(`${serachStudentByRegNoAPIURL}?sid=${sId}&bid=${scId}&regNo=${rNo}`)
            await axios.get(`${serachStudentByRegNoAPIURL}?sid=${sId}&bid=${scId}&regNo=${rNo}`)
                .then(res => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        // const filteredByRegCode = tempData.filter(student => student.regCode == rNo);
                        console.log(JSON.stringify(tempData.currentClassId))
                        setStudentData([tempData]);
                        setFilteredData(tempData);
                        setLoaderOption(false);
                    }
                });

            await axios.get(`${getStudentDetailsByRegNoAPIURL}?sid=${sId}&bid=${scId}&regNo=${rNo}`)
                .then(res => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        setStudentAllDetails(tempData);

                        setLoaderOption(false);
                    }
                });

            await axios.get(`${getStudentAcademicsDataAPIURL}?sid=${sId}&bid=${scId}&regNo=${rNo}&year=${authUser.sessionYear}`)
                .then(res => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        setStudentAllAcademicsDetails(tempData);
                        // Retrieve rollNo just once
                        const rollNo = tempData.length > 0 ? tempData[0].rollNo : null;
                        setStudRolNo(rollNo);
                        setLoaderOption(false);
                    }
                });


            await axios.get(`${getStudentFeeDataAPIURL}?sid=${sId}&bid=${scId}&regNo=${rNo}&sessionYear=${authUser.sessionYear}&classId=${cId}`)
                .then(res => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        setStudenFeeDetails(tempData);
                        console.log(JSON.stringify(tempData))
                        setLoaderOption(false);
                    }
                });
        }
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };


    if (loaderOption === true) {
        return <Loader />;
    } else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <div>
                        {studentData.map((student, index) => {
                            // console.log(student.studentImg)
                            return (
                                <>
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '20px',
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            margin: '10px 0',
                                            backgroundColor: '#d5dbdb'
                                        }}
                                    >

                                        <div style={{ marginRight: '20px' }}>
                                            <img
                                                src={student.studentImg ? `${imagePath}${student.studentImg}` : studImg}
                                                alt={student.studentNameMr || 'User'}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                }}
                                                onError={(e) => { e.target.src = studImg; }}
                                            />
                                        </div>


                                        <div>
                                            <h4
                                                style={{
                                                    margin: '5px 0',
                                                    color: '#243075',
                                                }}
                                            >
                                                {(bMedium == 1) ? student.studentNameMr : student.studentNameEn || 'N/A'}
                                            </h4>
                                            <p style={{ margin: '5px 0' }}>
                                                <strong>Reg. No :</strong> <span>{student.regCode || 'N/A'}</span>
                                            </p>
                                            <p style={{ margin: '5px 0' }}>
                                                <strong>Class :</strong> <span>{(bMedium == 1) ? student.currentClassNameMr + " " + student.currentDivNameMr : student.currentClassNamEn + " " + student.currentDivNamEn || 'N/A'}</span>
                                            </p>
                                            <p style={{ margin: '5px 0' }}>
                                                <strong>School :</strong> <span>{scName + " " + "(" + dName + ")" || 'N/A'}</span>
                                            </p>
                                            {/* <div className="col-sm-4"> */}
                                            <button type='button' onClick={() => navigate(`/Home/ssd1103/${scId}/${sId}`, {
                                                state: {
                                                    schoolNameMr: scName,
                                                    sectionNameMr: dName,
                                                    bMedium: bMedium
                                                }
                                            })} className="btn btn-sm btn-danger">Back</button>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        )}
                    </div>

                    <div className={classes.imageCard}>
                        {/* Vertical Tabs */}
                        <Tabs
                            // orientation="vertical"
                            value={selectedTab}
                            onChange={handleTabChange}
                            aria-label="Student Tabs"
                            variant="scrollable"
                        >
                            <Tab label="Basic Details" className={classes.tab} value={0} />
                            {/* <Tab label="Attendance" className={classes.tab} /> */}
                            <Tab label="Academics" className={classes.tab} value={1} />
                            {/* <Tab label="Documents" className={classes.tab} /> */}
                            <Tab label="Fees" className={classes.tab} value={2} />
                            {/* <Tab label="Certificates" className={classes.tab} /> */}
                        </Tabs>
                    </div>

                    <div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '20px',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            margin: '10px 0',
                            backgroundColor: '#d5dbdb'
                        }}>
                            <div className="">
                                {/* Tab Content */}
                                {selectedTab === 0 && (
                                    <div className={classes.tabPanel}>
                                        <div className="row">
                                            {/* First Column */}
                                            <div className="col-md-6">

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Mother Name:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                                            {(bMedium == 1) ? studetAllDetails.motherNameMr : studetAllDetails.motherNameEn}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Aadhar No:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>{studetAllDetails.aadharNo}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Saral No:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>{studetAllDetails.saralNo}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Address:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>{studetAllDetails.address}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Religion:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                                            {(bMedium == 1) ? studetAllDetails.religionNameMr : studetAllDetails.religionNameEn}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Caste:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                                            {(bMedium == 1) ? studetAllDetails.casteNameMr : studetAllDetails.casteNameEn}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Caste Category:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                                            {(bMedium == 1) ? studetAllDetails.categoryNameMr : studetAllDetails.categoryNameEn}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Second Column */}
                                            <div className="col-md-6">
                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Nationality:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                                            {(bMedium == 1) ? studetAllDetails.nationalityMr : studetAllDetails.nationalityEn}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Birth Date:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>{studetAllDetails.birthDate}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Old School:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>{studetAllDetails.oldSchool}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Admission Date:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>{studetAllDetails.admissionDate}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Admitted Std:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                                            {(bMedium == 1) ? studetAllDetails.adClassMr : studetAllDetails.adClassEn}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row mb-2">
                                                    <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Current Std Since:</label>
                                                    <div className="col-sm-7">
                                                        <span style={{ fontSize: "14px", fontWeight: "500" }}>{studetAllDetails.sinceWhen}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {selectedTab === 1 && (
                                    <div className={classes.tabPanel}>
                                        {/* Data for Term 1 and Term 2 */}
                                        <div className="row">
                                            <div className="row mb-2">
                                                <label className="col-sm-2" style={{ fontSize: "16px", fontWeight: "600" }}>Roll No:</label>
                                                <div className="col-sm-7">
                                                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        {studRollNo}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* <div className="row mb-2">
                                                <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Obtained Marks:</label>
                                                <div className="col-sm-7">
                                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>{studetAllDetails.aadharNo}</span>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            {studetAllAcademicsDetails.map((item, index) => (
                                                <div key={item.termId} className="col-md-6 col-12" style={{ marginBottom: '20px' }}>
                                                    <Accordion style={{ width: '100%' }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1-content"
                                                            id="panel1-header"
                                                        >
                                                            <Typography component="span">Term {item.termId}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ backgroundColor: '#d4e6f1' }}>
                                                            <div className="row">
                                                                <div className="col-md-12 col-12" style={{ marginBottom: '10px' }}>
                                                                    {/* Special Progress */}
                                                                    <div className="row mb-2">
                                                                        <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Special Progress:</label>
                                                                        <div className="col-sm-7">
                                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.specialProgress}</span>
                                                                        </div>
                                                                    </div>

                                                                    {/* Needs Improvement */}
                                                                    <div className="row mb-2">
                                                                        <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Needs Improvement:</label>
                                                                        <div className="col-sm-7">
                                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.needsImprovement}</span>
                                                                        </div>
                                                                    </div>

                                                                    {/* Height */}
                                                                    <div className="row mb-2">
                                                                        <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Height (cm):</label>
                                                                        <div className="col-sm-7">
                                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.height}</span>
                                                                        </div>
                                                                    </div>

                                                                    {/* Weight */}
                                                                    <div className="row mb-2">
                                                                        <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Weight (kg):</label>
                                                                        <div className="col-sm-7">
                                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.weight}</span>
                                                                        </div>
                                                                    </div>

                                                                    {/* Hobbies */}
                                                                    <div className="row mb-2">
                                                                        <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Hobbies:</label>
                                                                        <div className="col-sm-7">
                                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.hobbies}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-2">
                                                                        <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Max Marks:</label>
                                                                        <div className="col-sm-7">
                                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.maxMarks}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-2">
                                                                        <label className="col-sm-5" style={{ fontSize: "15px", fontWeight: "600" }}>Obtained Marks:</label>
                                                                        <div className="col-sm-7">
                                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.obtainMarks}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {selectedTab === 2 && (
                                    <div className={classes.tabPanel}>
                                        <div className="row">
                                            {studeFeeDetails.map((item, index) => (
                                                <div className="col-md-12 col-12" key={index}>
                                                    {/* Fee Name */}
                                                    <div className="row mb-3">
                                                        <div className="col-12">
                                                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                                                                {bMedium == 1 ? item.feeNameMr : item.feeNameEn}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-2">
                                                        <label className="col-sm-7" style={{ fontSize: "15px", fontWeight: "600" }}>Fee Amount :</label>
                                                        <div className="col-sm-5">
                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.feeAmount}</span>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-2">
                                                        <label className="col-sm-7" style={{ fontSize: "15px", fontWeight: "600" }}>Received Amt. :</label>
                                                        <div className="col-sm-5">
                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.receivedAmount}</span>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-2">
                                                        <label className="col-sm-7" style={{ fontSize: "15px", fontWeight: "600" }}>Pending Amt. :</label>
                                                        <div className="col-sm-5">
                                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.pendingAmount}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>


                    </div>


                </div>
            </>
        );
    }
};

export default StudentCompleteDetails;
