import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import {
  classWithDivisionURL, printProgressCardClassDivAndTermWise,
  printFinalProgressCardClassDivAndTermWise, getExamListForResultSheet, getExamData,
  printProgressCardStudentWiseAndTermWise, printFinalProgressCardStudentWise,
  printProgressCardExamWiseClassDivWise, printProgressCardExamWiseStudentAPIURL,
  printFinalProgressCardJrCollegeClassDivWise, printFinalProgressCardJrCollegeStudentWise, getProgressCardOptionsAPIURL
} from '../Services/ExamReportAPIURL';
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';
import { getStudentList } from '../../FeeManagement/Services/FeeReceiptAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getStudentListByBranchAPIURL } from '../../CommonServices/StudentRegistrationAPIURL';
import { Checkbox, FormControlLabel } from '@mui/material';

const useStyles = makeStyles(theme => ({
  select: {
    maxHeight: 160,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  },

}));



const ProgressCard = () => {
  const classes = useStyles();
  const titleId = "Progress Card";
  const authUser = useSelector((state) => state.user.value);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  // loader on/off
  const [loaderOption, setLoaderOption] = useState(false);

  const [reportOptionId, setReportOptionId] = useState(1);
  const [optionId, setOptionId] = useState(1);
  const [id, setId] = useState();
  const [classDivData, setClassDivData] = useState([]);
  const [classDivObj, setClassDivObj] = useState(null);
  const [termObj, setTermObj] = useState(null);
  const [studentData, setStudentData] = useState([]);
  const [studentObj, setStudentObj] = useState(null);
  const [examData, setExamData] = useState([]);
  const [examObj, setExamObj] = useState(null);
  const [language, setLanguage] = useState('');
  const [classId, setClassId] = useState('');
  const [divisionId, setDivisionId] = useState('');
  const [date, setDate] = useState(null);
  const [pageSize, setPageSize] = useState(1)
  const [subOption, setSubOption] = useState(1)
  const [healthImprovement, setHealthImprovement] = useState(0);
  const [attendData, setAttendData] = useState(0);
  const [govtRule, setGovtRule] = useState(0);
  // console.log(healthImprovement + "     " + attendData)

  const HandleHealthImproveCheckbox = (e) => {
    setHealthImprovement(e.target.checked);
    if (e.target.checked == true) {
      setHealthImprovement(1);
    }
    else {
      setHealthImprovement(0);
    }

  }

  const HandleAttendDataCheckbox = (e) => {
    setAttendData(e.target.checked);
    if (e.target.checked == true) {
      setAttendData(1);
    }
    else {
      setAttendData(0);
    }
  }

  const HandleGovtRuleCheckbox = (e) => {
    setGovtRule(e.target.checked);
    if (e.target.checked == true) {
      setGovtRule(1);
    }
    else {
      setGovtRule(0);
    }
  }

  useEffect(() => {
    if (authUser.branchMedium == 1) {
      setLanguage(1);
    }
    else {
      setLanguage(2);
    }
  }, [authUser])

  //Student Entry required data
  const [countData, setCountData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limits, setLimits] = useState(200);
  const [numCal, setNumCall] = useState(0);

  useEffect(() => {
    // if (reportOptionId == 1) {
    getData();
    // }
    // else if (reportOptionId == 2) {
    //   getStudentDetails();
    // }
  }, [numCal, authUser])

  const getData = async () => {

    if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
      await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        .then((response) => {
          setClassDivData(response.data);

        }).catch((error) => {
          console.log(error);
        })

      if (offset === 0) {
        setLoaderOption(true)
      }
      //console.log(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
      await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
        .then((res) => {
          if (res.data !== null) {
            const tempData = res.data;
            //console.log(tempData.length)
            setStudentData(studentData.concat(tempData).filter(e => e.isLeft == 0 && e.sessionYear == authUser.sessionYear));
            setLoaderOption(false)
            //console.log("Data : " + JSON.stringify(data))
            // console.log("____________________________________________") 
            if (tempData.length === limits) {
              setOffset(offset + limits);
              setNumCall(numCal + 1);
              //console.log("Offset ===============" + offset);
            }
          }
        })
    }

    //console.log(`${getExamData}`)
    // await axios(`${getExamData}`)
    //   .then((response) => {
    //     setExamData(response.data);
    //   }).catch((error) => {
    //     console.log(error);
    //   })


  }

  const getStudentDetails = () => {
    if (authUser.deptId != null && authUser.branchId != null) {
      getStudentData();
    }
  }

  //get term wise exam
  const getExamListTermWise = async (tId) => {
    setExamData([]);
    if (classDivObj != null || studentObj != null) {
      //console.log(`${getExamListForResultSheet}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
      await axios(`${getExamListForResultSheet}?classId=${classId}&divId=${divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
        .then((response) => {
          setExamData(response.data);

        }).catch((error) => {
          console.log(error);
        })
    }
  }

  // useEffect(() => {
  // if (authUser.deptId != null || authUser.branchId != null) {
  //   getStudentData();
  // }
  // }, [numCal, authUser])

  const getStudentData = async () => {

    // await axios(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
    //   .then(res => {
    //     const enrollStudents = res.data.filter(e => e.isLeft == 0 && e.sessionYear == authUser.sessionYear)
    //     //console.log(JSON.stringify(enrollStudents));
    //     setStudentData(enrollStudents);
    //     //setLoaderOption(false);
    //   })
  }

  //class-div & termwise progress report
  const classDivAndTermWiseProgressCardPrint = async () => {
    setLoaderOption(true);
    if (moment((date == null) ? new Date() : date).format("DD/MM/YYYY") !== "Invalid date") {
      console.log(`${printProgressCardClassDivAndTermWise}?langId=${language}&termId=${termObj}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}&date=${(date != null) ? moment(date).format("DD/MM/YYYY") : null}`)
      await axios.get(`${printProgressCardClassDivAndTermWise}?langId=${language}&termId=${termObj}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}&date=${(date != null) ? moment(date).format("DD/MM/YYYY") : null}`)
        .then((response) => {

          if (response.data === "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data Not Found.")
          }
          else if (response.data != null) {
            console.log(response.data)
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          setLoaderOption(false);
          toast.error("Error Occurred, Try after sometime.");
          console.log("Progress Card failed: " + error)
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }
  }

  //class-div & final progress report
  const classDivAndTermWiseFinalProgressCardPrint = async () => {
    setLoaderOption(true);
    if (moment(date).format("DD/MM/YYYY") !== "Invalid date") {
      console.log(`${printFinalProgressCardClassDivAndTermWise}?langId=${language}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&date=${moment(date).format("DD/MM/YYYY")}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}`)
      await axios.get(`${printFinalProgressCardClassDivAndTermWise}?langId=${language}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&date=${moment(date).format("DD/MM/YYYY")}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}`)
        .then((response) => {

          if (response.data === "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data Not Found.")
          }
          else if (response.data != null) {
            console.log(response.data)
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          setLoaderOption(false);
          toast.error("Error Occurred, Try after sometime.");
          console.log("Progress Card failed: " + error)
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }

  }

  //student & termwise progress report
  const studentAndTermWiseProgressCardPrint = async () => {
    setLoaderOption(true);
    if (moment((date == null) ? new Date() : date).format("DD/MM/YYYY") !== "Invalid date") {
      console.log(`${printProgressCardStudentWiseAndTermWise}?langId=${language}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${studentObj.regCode}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}&date=${(date != null) ? moment(date).format("DD/MM/YYYY") : null}`)
      await axios.get(`${printProgressCardStudentWiseAndTermWise}?langId=${language}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${studentObj.regCode}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}&date=${(date != null) ? moment(date).format("DD/MM/YYYY") : null}`)
        .then((response) => {

          if (response.data === "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data Not Found.")
          }
          else if (response.data != null) {
            console.log(response.data)
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          setLoaderOption(false);
          toast.error("Error Occurred, Try after sometime.");
          console.log("Progress Card failed: " + error)
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }
  }

  //studentwise & final progress report
  const studentAndFinalProgressCardPrint = async () => {
    setLoaderOption(true);
    if (moment(date).format("DD/MM/YYYY") !== "Invalid date") {
      console.log(`${printFinalProgressCardStudentWise}?langId=${language}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${studentObj.regCode}&date=${moment(date).format("DD/MM/YYYY")}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}`)
      await axios.get(`${printFinalProgressCardStudentWise}?langId=${language}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${studentObj.regCode}&date=${moment(date).format("DD/MM/YYYY")}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}`)
        .then((response) => {

          if (response.data === "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data Not Found.")
          }
          else if (response.data != null) {
            console.log(response.data)
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          setLoaderOption(false);
          toast.error("Error Occurred, Try after sometime.");
          console.log("Progress Card failed: " + error)
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }


  }

  //classDiv wise ,termwise & examwise progress report
  const printClassDivAndExamWiseProgressCard = async () => {
    setLoaderOption(true);
    if (moment((date == null) ? new Date() : date).format("DD/MM/YYYY") !== "Invalid date") {
      console.log(`${printProgressCardExamWiseClassDivWise}?langId=${language}&termId=${termObj}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&examId=${examObj.id}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}&date=${(date != null) ? moment(date).format("DD/MM/YYYY") : null}`)
      await axios.get(`${printProgressCardExamWiseClassDivWise}?langId=${language}&termId=${termObj}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&examId=${examObj.id}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}&date=${(date != null) ? moment(date).format("DD/MM/YYYY") : null}`)
        .then((response) => {

          if (response.data === "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data Not Found.")
          }
          else if (response.data != null) {
            console.log(response.data)
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          setLoaderOption(false);
          toast.error("Error Occurred, Try after sometime.");
          console.log("Progress Card failed: " + error)
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }
  }

  //student wise ,termwise & examwise progress report
  const printStudentAndExamWiseProgressCard = async () => {
    setLoaderOption(true);
    if (moment((date == null) ? new Date() : date).format("DD/MM/YYYY") !== "Invalid date") {
      console.log(`${printProgressCardExamWiseStudentAPIURL}?langId=${language}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${studentObj.regCode}&examId=${examObj.id}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}&date=${(date != null) ? moment(date).format("DD/MM/YYYY") : null}`)
      await axios.get(`${printProgressCardExamWiseStudentAPIURL}?langId=${language}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${studentObj.regCode}&examId=${examObj.id}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&subOption=${subOption}&showHealth=${healthImprovement}&showAttendance=${attendData}&govRule=${govtRule}&date=${(date != null) ? moment(date).format("DD/MM/YYYY") : null}`)
        .then((response) => {

          if (response.data === "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data Not Found.")
          }
          else if (response.data != null) {
            console.log(response.data)
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          setLoaderOption(false);
          toast.error("Error Occurred, Try after sometime.");
          console.log("Progress Card failed: " + error)
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }
  }

  //class wise final report for Jr college
  const printclasFinalReportForJrCollegeProgressCard = async () => {
    setLoaderOption(true);
    if (moment(date).format("DD/MM/YYYY") !== "Invalid date") {
      console.log(`${printFinalProgressCardJrCollegeClassDivWise}?langId=${language}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&date=${moment(date).format("DD/MM/YYYY")}&branchMedium=${authUser.branchMedium}&showAttendance=${attendData}`)
      await axios.get(`${printFinalProgressCardJrCollegeClassDivWise}?langId=${language}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&date=${moment(date).format("DD/MM/YYYY")}&branchMedium=${authUser.branchMedium}&showAttendance=${attendData}`)
        .then((response) => {

          if (response.data === "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data Not Found.")
          }
          else if (response.data != null) {
            console.log(response.data)
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          setLoaderOption(false);
          toast.error("Error Occurred, Try after sometime.");
          console.log("Progress Card failed: " + error)
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }
  }

  //student wise final report for Jr college
  const printStudentFinalreportForJrCollegeProgressCard = async () => {
    setLoaderOption(true);
    if (moment(date).format("DD/MM/YYYY") !== "Invalid date") {
      console.log(`${printFinalProgressCardJrCollegeStudentWise}?langId=${language}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${studentObj.regCode}&date=${moment(date).format("DD/MM/YYYY")}&branchMedium=${authUser.branchMedium}&showAttendance=${attendData}`)
      await axios.get(`${printFinalProgressCardJrCollegeStudentWise}?langId=${language}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${studentObj.regCode}&date=${moment(date).format("DD/MM/YYYY")}&branchMedium=${authUser.branchMedium}&showAttendance=${attendData}`)
        .then((response) => {

          if (response.data === "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data Not Found.")
          }
          else if (response.data != null) {
            console.log(response.data)
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          setLoaderOption(false);
          toast.error("Error Occurred, Try after sometime.");
          console.log("Progress Card failed: " + error)
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }
  }

  //For classDiv term wise Progress report 
  const printTermWiseStudentReport = async () => {
    if (termObj == 3) {
      if (classDivObj != null) {
        if (authUser.deptId == 4 || (authUser.deptId != 1 && classDivObj.classId > 10)) {
          printStudentFinalreportForJrCollegeProgressCard();
        }
        else {
          studentAndFinalProgressCardPrint();
        }
      }

    } else {
      studentAndTermWiseProgressCardPrint();
    }
  }

  //For student term wise Progress report 
  const printTermWiseClassDivWiseReport = async () => {
    if (termObj == 3) {
      if (classDivObj != null) {
        if (authUser.deptId == 4 || (authUser.deptId != 1 && classDivObj.classId > 10)) {
          printclasFinalReportForJrCollegeProgressCard();
        }
        else {
          classDivAndTermWiseFinalProgressCardPrint();
        }
      }
    } else {
      classDivAndTermWiseProgressCardPrint();
    }
  }

  //For classDiv exam wise progress report
  const printExamWiseClassDivWiseReport = async () => {
    if (termObj == 3) {
      if (classDivObj != null) {
        if (authUser.deptId == 4 || (authUser.deptId != 1 && classDivObj.classId > 10)) {
          printclasFinalReportForJrCollegeProgressCard();
        }
        else {
          classDivAndTermWiseFinalProgressCardPrint();
        }
      }
    } else {
      printClassDivAndExamWiseProgressCard();
    }
  }

  //For student exam wise progress report
  const printExamWiseStudentReport = async () => {
    if (termObj == 3) {
      if (classDivObj != null) {
        if (authUser.deptId == 4 || (authUser.deptId != 1 && classDivObj.classId > 10)) {
          printStudentFinalreportForJrCollegeProgressCard();
        }
        else {
          studentAndFinalProgressCardPrint();
        }
      }
    } else {
      printStudentAndExamWiseProgressCard();
    }
  }

  //call reports onSubmit
  const getProgressCardReport = e => {
    e.preventDefault();
    // if (classDivObj != null) {
    //   console.log(authUser.deptId + "==========" + classDivObj.classId)
    // }
    if (reportOptionId == 1) {
      if (optionId == 1) {
        printTermWiseClassDivWiseReport();
      }
      else if (optionId == 2) {
        printExamWiseClassDivWiseReport();
      }
    }
    else if (reportOptionId == 2) {
      if (optionId == 1) {
        printTermWiseStudentReport();
      }
      else if (optionId == 2) {
        printExamWiseStudentReport();
      }
    }
  }

  useEffect(() => {
    if (authUser.deptId != null && authUser.branchId != null) {
      getProgressCardMsg();
    }
  }, [authUser])

  const getProgressCardMsg = async () => {
    // console.log(`${getProgressCardOptionsAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
    await axios.get(`${getProgressCardOptionsAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setHealthImprovement(res.data.healthImprovement);
        setAttendData(res.data.attendanceData);
      })
  }

  if (loaderOption == true) {
    return <><Loader /></>
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />

        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
          {/* Form Data start */}
          <form onSubmit={getProgressCardReport}>
            <div className="form-data">
              <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

              <div className="row">
                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Language :</label>
                <div className='col-sm-3'>
                  <FormControl fullWidth margin='dense'>
                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Language</InputLabel>
                    <Select
                      MenuProps={{ classes: { paper: classes.select } }}
                      size='small'
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={language}
                      label="Language"
                      onChange={(e) => {
                        setLanguage(e.target.value);
                        setOptionId(1);
                        setReportOptionId(1);
                        setStudentObj(null);
                        setClassDivObj(null);
                        setDate(null);
                        setTermObj(null);
                        setExamObj(null);
                        setPageSize(1);
                        setSubOption(1);
                        // setHealthImprovement(0);
                        // setAttendData(0);
                      }}
                    >
                      <MenuItem value={1}>Marathi</MenuItem>
                      <MenuItem value={2}>English</MenuItem>
                      <MenuItem hidden={(authUser.trustId == 4) ? false : true} value={3}>Urdu</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row my-2">
                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                <div className='col-sm-3'>
                  <FormControl fullWidth margin='dense'>
                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                    <Select
                      MenuProps={{ classes: { paper: classes.select } }}
                      size='small'
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={optionId}
                      label="Option"
                      onChange={(e) => {
                        setOptionId(e.target.value);
                        setReportOptionId(1);
                        setStudentObj(null);
                        setClassDivObj(null);
                        setDate(null);
                        setTermObj(null);
                        setExamObj(null);
                        setPageSize(1);
                        setSubOption(1);
                        // setHealthImprovement(0);
                        // setAttendData(0);
                      }}
                    >
                      <MenuItem value={1}>Term wise</MenuItem>
                      <MenuItem value={2}>Exam wise</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                <div className='col-sm-3'>
                  <FormControl fullWidth margin='dense'>
                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                    <Select
                      MenuProps={{ classes: { paper: classes.select } }}
                      size='small'
                      required
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reportOptionId}
                      label="Select Option"
                      onChange={(e) => {
                        setReportOptionId(e.target.value);
                        setStudentObj(null);
                        setClassDivObj(null);
                        setDate(null);
                        setTermObj(null);
                        setExamObj(null);
                        setPageSize(1);
                        setSubOption(1);
                        // setHealthImprovement(0);
                        // setAttendData(0);
                      }}
                    >
                      <MenuItem value={1}>Class-Division Wise</MenuItem>
                      <MenuItem value={2}>Student Wise</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row my-2">
                {(() => {
                  if (reportOptionId == 1) {
                    return (
                      <>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                        <div className='col-sm-3'>
                          <Autocomplete
                            id="combo-box-demo"
                            PaperComponent={({ children }) => (
                              <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onClose={() => {
                              setOpen(false);
                            }}
                            loading={loading}
                            options={classDivData}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                            size="small"
                            value={classDivObj}
                            onChange={(event, newValue) => {
                              setClassDivObj(newValue);
                              setTermObj(null);
                              setStudentObj(null);
                              setDate(null);
                              setExamObj(null);
                              setPageSize(1);
                              setSubOption(1);
                              // setHealthImprovement(0);
                              // setAttendData(0);
                              if (newValue != null) {
                                setClassId(newValue.classId);
                                setDivisionId(newValue.divisionId);
                              }
                              else {
                                setTermObj(null);
                                setStudentObj(null);
                                setDate(null);
                                setExamObj(null);
                                setClassId('');
                                setDivisionId('');
                                setPageSize(1);
                                setSubOption(1);
                                // setHealthImprovement(0);
                                // setAttendData(0);

                              }
                            }}
                            renderInput={params => (
                              <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                required={(reportOptionId == 1) ? true : false} />
                            )}
                          />
                        </div>
                      </>
                    )
                  }
                  else if (reportOptionId == 2) {
                    return (
                      <>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Student :</label>
                        <div className='col-sm-4'>
                          <Autocomplete
                            id="combo-box-demo"
                            PaperComponent={({ children }) => (
                              <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onClose={() => {
                              setOpen(false);
                            }}
                            loading={loading}
                            options={studentData}
                            value={studentObj}
                            onChange={(event, newValue) => {
                              //console.log(newValue);
                              setStudentObj(newValue);
                              setTermObj(null);
                              setClassDivObj(null);
                              setDate(null);
                              setExamObj(null);
                              setPageSize(1);
                              setSubOption(1);
                              // setHealthImprovement(0);
                              // setAttendData(0);
                              if (newValue != null) {
                                setClassId(newValue.currentClassId);
                                setDivisionId(newValue.currentDivisionId);
                              }
                              else {
                                setTermObj(null);
                                setClassDivObj(null);
                                setDate(null);
                                setExamObj(null);
                                setClassId('');
                                setDivisionId('');
                                setPageSize(1);
                                setSubOption(1);
                                // setHealthImprovement(0);
                                // setAttendData(0);
                              }
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + ((option.studentNameMr == "") ? option.studentNameEn : option.studentNameMr) + " " + option.currentClassMr + "  " + option.currentDivisionMr :
                              option.regCode + " - " + ((option.studentNameEn == "") ? option.studentNameMr : option.studentNameEn) + " " + option.currentClassEn + "  " + option.currentDivisionEn}
                            size="small"
                            renderInput={params => (
                              <TextField {...params} margin="dense" label="Select Student" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                required={(reportOptionId == 2) ? true : false} />
                            )}
                          />
                        </div>
                      </>
                    )
                  }
                })()}

                <label className={(reportOptionId == 2) ? "col-sm-1" : "col-sm-2"} style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                <div className='col-sm-3'>
                  <FormControl fullWidth margin='dense'>
                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                    <Select
                      MenuProps={{ classes: { paper: classes.select } }}
                      size='small'
                      fullWidth
                      labelId="demo-simple-select-label"
                      required
                      id="demo-simple-select"
                      value={termObj}
                      label="Term"
                      onChange={(e) => {
                        setTermObj(e.target.value);
                        setDate(null);
                        setExamObj(null);
                        getExamListTermWise(e.target.value);
                        setPageSize(1);
                        setSubOption(1);
                        // setHealthImprovement(0);
                        // setAttendData(0);
                      }}
                    >
                      {subjectMarkTermData.map((item, index) => {
                        return (
                          <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {(() => {
                if ((classId <= 8 || authUser.deptId == 1) && optionId == 1) {
                  return (<>
                    <div className="row my-2">
                      <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Page Size :</label>
                      <div className='col-sm-3'>
                        <FormControl fullWidth margin='dense'>
                          <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Page Size</InputLabel>
                          <Select
                            MenuProps={{ classes: { paper: classes.select } }}
                            size='small'
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={pageSize}
                            label="Page Size"
                            onChange={(e) => {
                              setPageSize(e.target.value);

                              setSubOption(1);
                              // setHealthImprovement(0);
                              // setAttendData(0);
                            }}
                          >
                            <MenuItem value={1}>Half Page</MenuItem>
                            <MenuItem value={2}>Full Page</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Option :</label>
                      <div className='col-sm-3'>
                        <FormControl fullWidth margin='dense'>
                          <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                          <Select
                            MenuProps={{ classes: { paper: classes.select } }}
                            size='small'
                            required
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subOption}
                            label="Select Option"
                            onChange={(e) => {
                              setSubOption(e.target.value);
                              // setHealthImprovement(0);
                              // setAttendData(0);
                            }}
                          >
                            <MenuItem value={1}>Only Grade</MenuItem>
                            <MenuItem value={2}>Mark and Grade</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </>)
                }
              })()}

              {(() => {
                if (reportOptionId == 1) {
                  return (
                    <>
                      <div className="row my-2">
                        {(() => {
                          if (optionId == 2) {
                            return (
                              <>
                                <label className="col-sm-2" hidden={(termObj == 3) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam :</label>
                                <div className='col-sm-3' hidden={(termObj == 3) ? true : false}>
                                  <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                      <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={examData}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    value={examObj}
                                    onChange={(event, newValue) => {
                                      setExamObj(newValue);
                                    }}
                                    renderInput={params => (
                                      <TextField {...params} margin="dense" label="Select Exam" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                        required={(reportOptionId == 1 && optionId == 2 && termObj != 3) ? true : false} />
                                    )}
                                  />
                                </div>
                              </>
                            )
                          }
                        })()}

                        <label hidden={(termObj === 1 || termObj === null) ? true : false} className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date :</label>
                        <div className='col-sm-3' hidden={(termObj === 1 || termObj === null) ? true : false}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="Date"
                              inputFormat="dd/MM/yyyy"
                              value={date}
                              onChange={(newValue) => {
                                setDate(newValue);
                              }}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                  margin='dense'
                                  variant="outlined"
                                  InputLabelProps={{ style: { fontSize: 14 } }}
                                  required={(termObj !== 1 && termObj !== null && reportOptionId == 1) ? true : false}
                                  fullWidth
                                  autoComplete='off'
                                  size="small" />}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>

                    </>
                  )
                }
                else if (reportOptionId == 2) {
                  return (
                    <>
                      <div className="row my-2">
                        {(() => {
                          if (optionId == 2) {
                            return (
                              <>
                                <label className="col-sm-2" hidden={(termObj == 3) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam :</label>
                                <div className='col-sm-3' hidden={(termObj == 3) ? true : false}>
                                  <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                      <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={examData}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    value={examObj}
                                    onChange={(event, newValue) => {
                                      setExamObj(newValue);
                                    }}
                                    renderInput={params => (
                                      <TextField {...params} margin="dense" label="Select Exam" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                        required={(reportOptionId == 2 && optionId == 2 && termObj != 3) ? true : false} />
                                    )}
                                  />
                                </div>
                              </>
                            )
                          }
                        })()}

                        <label hidden={(termObj === 1 || termObj === null) ? true : false} className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date :</label>
                        <div className='col-sm-3' hidden={(termObj === 1 || termObj === null) ? true : false}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="Date"
                              inputFormat="dd/MM/yyyy"
                              value={date}
                              onChange={(newValue) => {
                                setDate(newValue);
                              }}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                  margin='dense'
                                  variant="outlined"
                                  InputLabelProps={{ style: { fontSize: 14 } }}
                                  required={(termObj !== 1 && termObj !== null && reportOptionId == 2) ? true : false}
                                  fullWidth
                                  autoComplete='off'
                                  size="small" />}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>

                    </>
                  )
                }
              })()}

              <div className="row mt-2">
                <div className="col-sm-12 offset-sm-2">
                  {(() => {
                    if (pageSize == 2) {
                      return (
                        <>

                          <FormControlLabel control={<Checkbox checked={healthImprovement} onChange={HandleHealthImproveCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Heath Improvement" />

                        </>
                      )
                    }
                  })()}

                  <FormControlLabel control={<Checkbox checked={attendData} onChange={HandleAttendDataCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Attendance Data" />
                </div >
              </div>

              <div className="row mt-2">
                <div className="col-sm-12 offset-sm-2">
                  {(() => {
                    if (classDivObj != null) {
                      if (classDivObj.classId == 5 || classDivObj.classId == 8) {
                        return (
                          <FormControlLabel control={<Checkbox checked={govtRule} onChange={HandleGovtRuleCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Govt. Rule" />
                        )
                      }
                    }
                  })()}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-4 offset-sm-2">
                  <button type="submit" className="btn btn-primary btn-sm">Show</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* <ToastContainer position="top-right" theme="colored" /> */}
      </>
    )
  }


}

export default ProgressCard 