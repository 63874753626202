export const PrincipalMenu = [
	{
		id: 1,
		name: "Academic"
	},
	{
		id: 2,
		name: "Account"
	},
	{
		id: 4,
		name: "Mobile App"
	},
	{
		id: 5,
		name: "Budget"
	},
	{
		id: 6,
		name: "Payroll"
	},
	{
		id: 8,
		name: "Inventory Store"
	}
]

export const ClerkMenu = [
	{
		id: 1,
		name: "Academic"
	},
	{
		id: 2,
		name: "Account"
	},
	{
		id: 4,
		name: "Mobile App"
	},
	{
		id: 5,
		name: "Budget"
	},
	{
		id: 6,
		name: "Payroll"
	},
	{
		id: 8,
		name: "Inventory Store"
	}
]

export const SupervisorMenu = [
	{
		id: 1,
		name: "Academic"
	}
]

export const TeacherMenu = [
	{
		id: 1,
		name: "Academic"
	},
	{
		id: 4,
		name: "Mobile App"
	},
	{
		id: 9,
		name: "MDM"
	}
]

export const AdminMenu = [
	{
		id: 1,
		name: "Master"
	}
]

export const TermFee = [
	{
		id: 2,
		name: "Account"
	}
]

export const sansthaAcademic = [
	{
		id: 1,
		name: "Sanstha"
	},
	{
		id: 2,
		name: "Endowment"
	}
]

export const sansthaClerk = [
	{
		id: 2,
		name: "Account"
	},
	{
		id: 5,
		name: "Budget"
	}
]

export const dEdCollege = [
	{
		id: 2,
		name: "Account"
	},
	{
		id: 5,
		name: "Budget"
	},
	{
		id: 10,
		name: "Endowments"
	},
]

export const EEAccount = [
	{
		id: 2,
		name: "Account"
	},
	{
		id: 5,
		name: "Budget"
	},
	
]

export const LibraryMenu = [
	{
		id: 3,
		name: "Library"
	},
]

export const LibraryForPrincipalMenu = [
	{
		id: 1,
		name: "Academic"
	},
	{
		id: 2,
		name: "Account"
	},
	{
		id: 3,
		name: "Library"
	},
	{
		id: 4,
		name: "Mobile App"
	},
	{
		id: 5,
		name: "Budget"
	},
	{
		id: 6,
		name: "Payroll"
	},
	{
		id: 8,
		name: "Inventory Store"
	}
]

export const HostelMenu = [
	{
		id: 2,
		name: "Account"
	},
	{
		id: 5,
		name: "Budget"
	},
	{
		id: 7,
		name: "Hostel"
	},
]

export const DengiMenu = [
	{
		id: 2,
		name: "Account"
	}
]
export const onlyHostelMenu = [
	{
		id: 7,
		name: "Hostel"
	}
]

export const onlyLibraryMenu = [
	{
		id: 3,
		name: "Library"
	},
]

export const onlyEndowmentMenu = [
	{
		id: 10,
		name: "Endowments"
	},
]


