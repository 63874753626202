import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import { getStudentListDaud } from '../../CommonServices/StudentRegistrationAPIURL';
import { printBonafideAPIURL, printMultiStudentBonafideAPIURL, getPrintBonafideAPIURL, getStudentList, classWithDivisionURL, getStudentListByClass, generateBulkBonafideReportAPIURL, getBonafideOptionsAPIURL, printPreviousYearBonafideAPIURL } from '../Services/BonafideAPIURL';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { GetYearAPIURL } from '../../CommonServices/LoginAPIURL';
import { Input } from '@mui/material';
const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const Bonafide = () => {
    const classes = useStyles();
    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const navigate = useNavigate();

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Bonafide";
    const [data, setData] = useState([]);
    const [studentId, setStudentId] = useState(null);
    const [multiStudentId, setMultiStudentId] = useState(null);
    const [studentObj, setStudentObj] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState("disabled");
    //const [languageObj, setLanguageObj] = useState(null);
    const [languageId, setLanguageId] = useState(1);
    const [optionId, setOptionId] = useState(1);
    const [waterMark, setWaterMark] = useState(0);
    const [photoBox, setPhotoBox] = useState(0);
    const [officeCopy, setOfficeCopy] = useState(0);
    const [isLeft, setIsLeft] = useState(0)
    const[yearId ,setYearId]=useState(1);
 const [year, setYear] = useState([]);
    const[yearLabel ,setYearLabel]=useState(null);
   const [yearIndex, setYearIndex] = useState("");
     
     const [prevsessionYear, setPrevSessionYear] = useState("");
     const[classId,setClassId] = useState("");
     const[divId,setDivId] = useState("");
     
       const [fromDate, setFromDate] = useState("");
       const [toDate, setTodate] = useState("");

    const HandleWaterMarkCheckbox = (e) => {
        setWaterMark(e.target.checked);
        if (e.target.checked == true) {
            setWaterMark(1);
        }
        else {
            setWaterMark(0);
        }
    }

    const handleIsLeftCheckBox = (e) => {
        setIsLeft(e.target.checked)

        if (e.target.checked == true) {
            setIsLeft(1);
        }
        else {
            setIsLeft(0);
        }
    }
    const HandlePhotoBoxCheckbox = (e) => {
        setPhotoBox(e.target.checked);
        if (e.target.checked == true) {
            setPhotoBox(1);
        }
        else {
            setPhotoBox(0);
        }
    }
    const HandleOfficeCopyCheckbox = (e) => {
        setOfficeCopy(e.target.checked);
        if (e.target.checked == true) {
            setOfficeCopy(1);
        }
        else {
            setOfficeCopy(0);
        }
    }

    //if option is 2 and get students by class
    const [studByClassData, setStudByClassData] = useState([]);
    const [studentByClassObj, setStudentByClassObj] = useState(null);

    //Student Entry required data
    const [countData, setCountData] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);


    const [classDivData, setclassDivData] = useState([]);
    const [classDivObj, setclassDivObj] = useState(null);

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [dataLeft, setDataLeft] = useState([]);
    //console.log(JSON.stringify(dataLeft))
    const [leftFilteredData, setLeftFilteredData] = useState([]);
    const [leftSearchTerm, setLeftSearchTerm] = useState([]);
    const [right, setRight] = useState([]);
    const [rightFilteredData, setRightFilteredData] = useState([]);
    //console.log(JSON.stringify(rightFilteredData))
    const [rightSearchTerm, setRightSearchTerm] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    //if option is 2 and get students by class
    const getStudentsByClass = async (cId, dId) => {
        setLoaderOption(true);
        await axios(`${getStudentListByClass}?classId=${cId}&divId=${dId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setLeft(res.data);
                setLeftFilteredData(res.data);
                setDataLeft(res.data);
                setLoaderOption(false);
            })
    }

    const handleToggle = (value) => () => {
        //console.log(value);    
        const currentIndex = checked.indexOf(value);
        //console.log("Data="+currentIndex)       
        const newChecked = [...checked];

        if (currentIndex === -1) {
            //console.log("Checked="+JSON.stringify(value.id))           
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        //console.log(newChecked.map(e=>e.id).join(','))
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setRightFilteredData(right.concat(left));
        setLeft([]);
        setLeftFilteredData([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setRightFilteredData(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setLeftFilteredData(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setLeftFilteredData(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setRightFilteredData(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setLeftFilteredData(left.concat(right));
        setRight([]);
        setRightFilteredData([]);
    };


    useEffect(() => {
        setLeftFilteredData(
            left.filter((item) =>
                item.regNo.toString().toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                item.studentNameMr.toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(leftSearchTerm.toLowerCase())

            ))
    }, [leftSearchTerm])

    useEffect(() => {
        setRightFilteredData(
            right.filter((item) =>
                item.regNo.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                item.studentNameMr.toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(rightSearchTerm.toLowerCase())
            ))
    }, [rightSearchTerm])



    const leftListData = (leftdata) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>

            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "200px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={leftSearchTerm}
                        onChange={e => setLeftSearchTerm(e.target.value)}
                    /></span>
                </div>

                {leftdata.sort((a, b) => a.regNo > b.regNo ? 1 : -1).map((value) => {
                    const labelId = value.regNo;
                    return (
                        <ListItem
                            key={value.regNo}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={(languageId == 1) ? (value.regNo + " - " + ((value.studentNameMr == "") ? value.studentNameEn : value.studentNameMr)) : (value.regNo + " - " + ((value.studentNameEn == "") ? value.studentNameMr : value.studentNameEn))} primaryTypographyProps={{ fontSize: '14px', fontWeight: "540" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const rightListData = (rightData) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>
            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "200px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={rightSearchTerm}
                        onChange={e => setRightSearchTerm(e.target.value)}
                    /></span>
                </div>
                {rightData.sort((a, b) => a.regNo > b.regNo ? 1 : -1).map((value) => {
                    const labelId = value.regNo;
                    //setRegNum(value.regNo);
                    //console.log(value.regNo)
                    return (
                        <ListItem
                            key={value.regNo}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={(languageId == 1) ? (value.regNo + " - " + ((value.studentNameMr == "") ? value.studentNameEn : value.studentNameMr)) : (value.regNo + " - " + ((value.studentNameEn == "") ? value.studentNameMr : value.studentNameEn))} primaryTypographyProps={{ fontSize: '14px', fontWeight: "540" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );


    const PrintClassDivWiseBonafide = async () => {
        setLoaderOption(true);
        let saveObj = right;
        right.map((e, index) => {
            saveObj[index] = ({
                ...e,
                "langId": languageId,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId,
                "isWatermark": waterMark,
                "photoBox": photoBox,
                "officeCopy": officeCopy,
                "currSessionYear": authUser.sessionYear
            })
        })
        //console.log(JSON.stringify(saveObj))         
        await axios.post(`${generateBulkBonafideReportAPIURL}`, saveObj)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")

                }
                else if (response.data != null) {
                    window.open(`${getPrintBonafideAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Bonafide Report failed: " + error)
            })

    }
    // useEffect(() => {
    //     getYearData();
    // }, [authUser])

/*const getYearData = async() =>{

    axios.get(GetYearAPIURL)
    .then((response) => {
        setYear(response.data);
        setYearIndex(0);
        setYearLabel(response.data[0].yearLabel);
        setPrevSessionYear(response.data[0].sessionYear);
        setFromDate(response.data[0].fromDate);
        setTodate(response.data[0].toDate);
    })

}*/
    useEffect(() => {
        getClassDivData();
    }, [authUser])

    // const handleYear = (selectedIndex) => {
    //     console.log("Selected Index",selectedIndex);
    //     const selectedYear = year[selectedIndex];
    //     setYearIndex(selectedIndex); 
    //     if (selectedIndex) {
    //          setPrevSessionYear(selectedYear.sessionYear);
    //       }
       
    //     console.log('Selected Year:', selectedYear);
    //   };
      
    const getClassDivData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setclassDivData(response.data);
                    setDivId(response.data[0].divisionId);
                    setClassId(response.data[0].classId);

                }).catch((error) => {
                    console.log(error);
                })
        }

    }
  
    useEffect(() => {
        if (authUser.deptId !== null && authUser.branchId !== null) {
            getData();
        }
    }, [numCal, authUser])

    useEffect(() => {
        getBonafideOptionsData();
    }, [authUser])

    const handleClassDivChange = (event, newValue) => {
        setclassDivObj(newValue);
        if (newValue) {
          setClassId(newValue.classId); 
          setDivId(newValue.divisionId); 
        }
      };

    const getBonafideOptionsData = async () => {
        // {
        //     "id": 1,
        //     "watermark": 0,
        //     "photoBox": 0,
        //     "officeCopy": 0,
        //     "studying": 0,
        //     "sectionId": 3,
        //     "branchId": 1
        // }
        if (authUser.deptId != null && authUser.branchId != null) {
            console.log(`${getBonafideOptionsAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getBonafideOptionsAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data != null) {
                        setWaterMark(res.data.watermark);
                        setPhotoBox(res.data.photoBox);
                        setOfficeCopy(res.data.officeCopy);
                        setIsLeft(res.data.studying);
                    }
                })
        }
    }



    const getData = async () => {
        if (offset === 0) {
            setLoaderOption(true)
        }
        await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
            .then((res) => {
                if (res.data !== null) {
                    const tempData = res.data;
                    //console.log(tempData.length)
                    setData(data.concat(tempData));
                    setLoaderOption(false)
                    //console.log("Data : " + JSON.stringify(data))
                    // console.log("____________________________________________")
                    if (tempData.length === limits) {
                        setOffset(offset + limits);
                        setNumCall(numCal + 1);
                        //console.log("Offset ===============" + offset);
                    }
                }
            })
    }

    useEffect(() => {
        if (studentId == "" || studentId == null || languageId == "" || languageId == null) {
            setBtnDisabled("disabled")
        }
        else {
            setBtnDisabled("")
        }
    }, [studentId])


    const printBonafide = () => {
        setLoaderOption(true);
        // setStudentId(1);
        // setStudentObj(null);
        //localhost:8080/institute/sss/administration/reports/generateBonafideReport?langId=1&regNo=10011&sid=3&bid=1
        //console.log(`${printBonafideAPIURL}?langId=${(languageId == 1) ? 1 : 2}&regNo=${studentId}&sid=${authUser.deptId}&bid=${authUser.branchId}&waterMark=${waterMark}`)
        axios.get(`${printBonafideAPIURL}?langId=${languageId}&regNo=${studentId}&sid=${authUser.deptId}&bid=${authUser.branchId}&waterMark=${waterMark}&isLeft=${isLeft}&photoBox=${photoBox}&officeCopy=${officeCopy}&currSessionYear=${authUser.sessionYear}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    console.log(response.data)
                    window.open(`${getPrintBonafideAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Bonafide failed: " + error)
            })
    }

    const printMultiStudentBonafide = () => {
        setLoaderOption(true);
        // console.log(`${printMultiStudentBonafideAPIURL}?langId=${languageId}&regNo=${multiStudentId}&sid=${authUser.deptId}&bid=${authUser.branchId}&waterMark=${waterMark}&isLeft=${isLeft}&photoBox=${photoBox}`)
        axios.get(`${printMultiStudentBonafideAPIURL}?langId=${languageId}&regNo=${multiStudentId}&sid=${authUser.deptId}&bid=${authUser.branchId}&waterMark=${waterMark}&isLeft=${isLeft}&photoBox=${photoBox}&officeCopy=${officeCopy}&currSessionYear=${authUser.sessionYear}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                    setMultiStudentId(null)
                }
                else if (response.data != null) {
                    setMultiStudentId(null)
                    console.log(response.data)
                    window.open(`${getPrintBonafideAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Bonafide failed: " + error)
            })
    }
    const printPreviousYearBonafide =() => {
        setLoaderOption(true);
     
        axios.get(`${printPreviousYearBonafideAPIURL}?langId=${languageId}&regNo=${studentId}&classId=${classId}&divId=${divId}&sid=${authUser.deptId}&bid=${authUser.branchId}&waterMark=${waterMark}&isLeft=${isLeft}&photoBox=${photoBox}&officeCopy=${officeCopy}&sessionYear=${prevsessionYear}&currSessionYear=${authUser.sessionYear}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                    
                }
                else if (response.data != null) {
                    
                    console.log(response.data)
                    window.open(`${getPrintBonafideAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Bonafide failed: " + error)
            })
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>

                    <div className="row mb-4">
                        <div className='col-sm-12'>
                            <button className='btn btn-warning btn-sm' onClick={() => navigate('/Home/ad221MbF/')}>Manual Bonafide</button>
                        </div>
                    </div>
                    <div className='row my-2'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Language :</label>
                        <div className='col-sm-3'>
                            <FormControl fullWidth margin='dense'>
                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    size='small'
                                    required
                                    fullWidth

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={languageId}
                                    label="Select Language"
                                    onChange={(e) => {
                                        setLanguageId(e.target.value);
                                        setOptionId(1);
                                        setLeft([]);
                                        setLeftFilteredData([]);
                                        setDataLeft([]);
                                        setclassDivObj(null);
                                        setStudentObj(null);
                                    }}
                                >
                                    <MenuItem value={1}>Marathi</MenuItem>
                                    <MenuItem value={2}>English</MenuItem>
                                </Select>
                            </FormControl>

                        </div>

                        <div className='col-sm-2 mt-1'>
                            <FormControlLabel control={<Checkbox checked={waterMark} onChange={HandleWaterMarkCheckbox} />} label="Watermark" />
                        </div>
                        <div className='col-sm-2 mt-1'>
                            <FormControlLabel control={<Checkbox checked={photoBox} onChange={HandlePhotoBoxCheckbox} />} label="Photo Box" />
                        </div>

                    </div>
                    <div className='row my-2'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Year:</label>
                        <div className='col-sm-3'>
                            <FormControl fullWidth margin='dense'>
                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select </InputLabel>
                                <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    size='small'
                                    required
                                    fullWidth

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={yearId}
                                    label="Select Year"
                                    onChange={(e) => {
                                        setYearId(e.target.value);
                                        setOptionId(1);
                                        setLeft([]);
                                        setLeftFilteredData([]);
                                        setDataLeft([]);
                                        setclassDivObj(null);
                                        setStudentObj(null);
                                    }}
                                >
                                    <MenuItem value={1}>Current Year</MenuItem>
                                    <MenuItem value={2}>Previous Year</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                        <div className='col-sm-2 mt-1 '>
                            <FormControlLabel control={<Checkbox checked={officeCopy} onChange={HandleOfficeCopyCheckbox} />} label="Office Copy" />
                        </div>

                        <div className='col-sm-2 mt-1 '>
                            <FormControlLabel control={<Checkbox checked={isLeft} onChange={handleIsLeftCheckBox} />} label={languageId === 1 ? "शिकत होता/होती" : "Was Studying"} />
                        </div>
                     

                    </div>
                    {yearId==2 &&(   <div className='row my-2'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Session Year:</label>
                        <div className='col-sm-3'>
                            <FormControl fullWidth margin='dense'>
                               <TextField
                               size="small"
                                 label="Enter Session Year"
                                value={prevsessionYear}
                                onChange={(e) => {
                                setPrevSessionYear(e.target.value); }}
                                margin="dense"
                                variant="outlined" 
                                type="number"
                                />
                            </FormControl>

                        </div>

                      

                    </div>
                    )}

                  



                 {yearId == 1 &&(   <div className='row my-2'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                        <div className='col-sm-3'>
                            <FormControl fullWidth margin='dense'>
                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    size='small'
                                    required
                                    fullWidth

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={optionId}
                                    label="Select Option"
                                    onChange={(e) => {
                                        setOptionId(e.target.value);
                                        setLeft([]);
                                        setLeftFilteredData([]);
                                        setDataLeft([]);
                                        setclassDivObj(null);
                                        setStudentObj(null);
                                    }}
                                >
                                    <MenuItem value={1}>Student Wise</MenuItem>
                                    <MenuItem value={2}>Class-Division Wise</MenuItem>
                                    <MenuItem value={3}>Multiple Student Wise</MenuItem>
                                </Select>
                            </FormControl>

                        </div>

                       
                    </div>)}

                    {(() => {
                        if (optionId == 1 || yearId == 2) {
                            return (
                                <>
                                    <div className='row my-2'>
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Student :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                //disableClearable
                                                //hidden={(languageId == null || languageId == "") ? true : false}
                                                options={data}
                                                style={{ fontSize: '14px' }}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, item) => {
                                                    setStudentId(item.regCode)
                                                    setStudentObj(item)
                                                }}
                                                value={studentObj}

                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + ((option.studentNameMr == "") ? option.studentNameEn : option.studentNameMr) : option.regCode + " - " + ((option.studentNameEn == "") ? option.studentNameMr : option.studentNameEn)}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(optionId == 1) ? true : false} />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {yearId === 1  &&(  <div className='mt-3 offset-sm-2'>
                                        <button
                                            onClick={() => printBonafide()}
                                            className='btn btn-primary btn-sm mb-2'
                                            style={{ margin: "0px 4px" }}
                                            disabled={btnDisabled}
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>

                                    </div>)}
                                
                                </>
                            )
                        }
                        else if (optionId == 2 ) {
                            return (
                                <>
                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={classDivData}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                value={classDivObj}
                                                onChange={(event, newvalue) => {
                                                    setclassDivObj(newvalue);
                                                    getStudentsByClass(newvalue.classId, newvalue.divisionId);
                                                }}

                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required={(optionId == 2) ? true : false} />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {
                                        (dataLeft == "" || dataLeft == null || dataLeft == [])
                                            ?
                                            ''
                                            :
                                            <div>
                                                <div className="row my-2">
                                                    <div className='col-sm-12 offset-sm-2'>
                                                        <Grid container spacing={2} alignItems="center"  >
                                                            <Grid item >{leftListData(leftFilteredData)}</Grid>
                                                            <Grid item>
                                                                <Grid container direction="column" alignItems="center"  >
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllRight}
                                                                        disabled={left.length === 0}
                                                                        aria-label="move all right"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        ≫
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleCheckedRight}
                                                                        disabled={leftChecked.length === 0}
                                                                        aria-label="move selected right"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        &gt;
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleCheckedLeft}
                                                                        disabled={rightChecked.length === 0}
                                                                        aria-label="move selected left"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        &lt;
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllLeft}
                                                                        disabled={right.length === 0}
                                                                        aria-label="move all left"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        ≪
                                                                    </button>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>{rightListData(rightFilteredData)}</Grid>
                                                        </Grid>

                                                    </div>
                                                </div>
                                            </div>
                                    }  

                                    <div className='mt-3 offset-sm-2'>
                                        <button
                                            onClick={() => PrintClassDivWiseBonafide()}
                                            className='btn btn-primary btn-sm mb-2'
                                            style={{ margin: "0px 4px" }}
                                            disabled={(classDivObj == null || classDivObj == "" || right == [] || right == null || right == "") ? true : false}
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                </>
                            )
                        }
                        else if (optionId == 3) {
                            return (
                                <>
                                    <div className='row my-2'>
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Student :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                id="outlined"
                                                margin='dense'
                                                label="Student Reg No"
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                size='small'
                                                style={{ fontSize: "14px", width: "450px" }}
                                                required={(optionId == 3) ? true : false}
                                                autoComplete="off"
                                                value={multiStudentId}
                                                onChange={(e) => setMultiStudentId(e.target.value)}
                                            />

                                        </div>
                                    </div>
                                    <div className='mt-3 offset-sm-2'>
                                        <button
                                            onClick={() => printMultiStudentBonafide()}
                                            className='btn btn-primary btn-sm mb-2'
                                            style={{ margin: "0px 4px" }}
                                            disabled={(multiStudentId == null || multiStudentId == "") ? true : false}
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>

                                    </div>
                                </>
                            )
                        }
                    })()}
             
                {yearId == 2 && (<div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={classDivData}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                value={classDivObj}
                                                onChange={ handleClassDivChange}

                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                                )}
                                            />
                                        </div>
                                       
                                        <div className='mt-3 offset-sm-2'>
                                        <button
                                            onClick={() => printPreviousYearBonafide()}
                                            className='btn btn-primary btn-sm mb-2'
                                            style={{ margin: "0px 4px" }}
                                            disabled={btnDisabled}
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>

                                    </div>
                                    
                                    </div>
                                    )}  
                                     </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default Bonafide