import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import { TablePagination, Tooltip } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getStudentApprovalAllocationData, saveStatusApprovalAPIURL, updateStatusApprovalForSansthaAPIURL } from '../Services/StudentApprovalAPIURL';
import axios from 'axios';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import ConditionAddStudentModal from './ConditionAddStudentModal';
import ApprovalStatusSelectBox from './ApprovalStatusSelectBox';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const prizeFor = [
    {
        id: 1,
        name: "Student",
    },
    {
        id: 2,
        name: "Teacher",
    },
    {
        id: 3,
        name: "Institute",
    }
]

const gender = [
    {
        id: 1,
        name: "विद्यार्थी / विद्यार्थिनी",
    },
    {
        id: 2,
        name: "विद्यार्थिनी",
    },
    {
        id: 3,
        name: "विद्यार्थी",
    },
]

const approvalStatus = [
    {
        id: 0,
        name: "In Process",
    },
    {
        id: 1,
        name: "Approved",
    },
    {
        id: 2,
        name: "Rejected",
    }
]

const ConditionDetailsTable = ({ data, filteredData, setData, setFilteredData, approveStatusDetail, pId, getData, ssForm, paritoshikName, yojanaInfo }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Approval"
    const [loaderOption, setLoaderOption] = useState(false);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [approve, setApprove] = useState(0)
        ;
    const TableHeading1 = [
        { label: 'बक्षिस कोणाला', key: 'prizeFor', isAction: true },
        { label: `इयत्ता`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn', isAction: true },
        { label: `विषय`, key: (authUser.branchMedium == 1) ? 'subjectNameMr' : 'subjectNamEn', isAction: true },
        { label: `लिंग`, key: 'genderId', isAction: true },
        { label: `रक्कम`, key: 'amount', isAction: true },
        { label: `Status`, key: 'status', isAction: true },
        { label: `Action`, key: 'action', isAction: true },
    ];

    const TableHeading2 = [
        { label: 'बक्षिस कोणाला', key: 'prizeFor', isAction: true },
        { label: `इयत्ता`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn', isAction: true },
        { label: `विषय`, key: (authUser.branchMedium == 1) ? 'subjectNameMr' : 'subjectNamEn', isAction: true },
        { label: `लिंग`, key: 'genderId', isAction: true },
        { label: `रक्कम`, key: 'amount', isAction: true },
        { label: `Status`, key: 'status', isAction: true },
        { label: `Approval Status`, key: 'approvalStatus', isAction: true },
        { label: `Action`, key: 'action', isAction: true },
    ];

    const TableHeading3 = [
        { label: 'बक्षिस कोणाला', key: 'prizeFor', isAction: true },
        { label: `इयत्ता`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn', isAction: true },
        { label: `विषय`, key: (authUser.branchMedium == 1) ? 'subjectNameMr' : 'subjectNamEn', isAction: true },
        { label: `लिंग`, key: 'genderId', isAction: true },
        { label: `रक्कम`, key: 'amount', isAction: true },
        { label: `Status`, key: 'status', isAction: true },
        { label: `Approval Status`, key: 'approvalStatus', isAction: true },
        { label: `Action`, key: 'action', isAction: true },
    ];



    const saveApprovalStatus = async (val, etran, pCode) => {
        setLoaderOption(true);
        if (authUser.branchId == 50) {
            console.log(`${updateStatusApprovalForSansthaAPIURL}?prizeCode=${pCode}&etranNo=${etran}&status=${val}&year=${authUser.sessionYear}`)
            await axios.put(`${updateStatusApprovalForSansthaAPIURL}?prizeCode=${pCode}&etranNo=${etran}&status=${val}&year=${authUser.sessionYear}`)
                .then(res => {
                    if (res.data == "UPDATED") {
                        setLoaderOption(false);
                        setApprove(0);
                        getData();
                        toast.success("Status updated successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("updated failed.")
                    }

                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }
        else {
            console.log(`${saveStatusApprovalAPIURL}?prizeCode=${pCode}&etranNo=${etran}&status=${val}&year=${authUser.sessionYear}`)
            await axios.put(`${saveStatusApprovalAPIURL}?prizeCode=${pCode}&etranNo=${etran}&status=${val}&year=${authUser.sessionYear}`)
                .then(res => {
                    if (res.data == "UPDATED") {
                        setLoaderOption(false);
                        setApprove(0);
                        getData();
                        toast.success("Status updated successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("updated failed.")
                    }

                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }
    }


    return (
        <>

            {/* <TitleLabel titleId={titleId} />
          <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}> */}
            {/* <div className='row mb-2' style={{ float: 'right' }}>
              <div className=''>
                <input
                  className='form-control form-control-sm'
                  type="text"
                  style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                  placeholder="Search Here"
                  value={searchTerm}
                  onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                />
              </div>
            </div> */}

            <div className="row mb-2 my-2 mt-4">
                <div className="">
                    <h4 style={{
                        textAlign: "left",
                        borderBottom: "1px solid #5D6D7E",
                        lineHeight: "0.1em",
                        margin: "10px 0 20px",
                        color: "#1430CF"
                    }}><span style={{
                        background: "#fff",
                        padding: "0 8px",
                    }}>Conditions</span></h4>
                </div>
            </div>

            <div className='table-responsive mt-3' style={{ overflowY: "hidden" }}>
                <table className="table table-bordered">
                    <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                        <tr>
                            {TableHeading2.map((item, index) => {
                                return (
                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }} key={index} scope="col">{`${item.label}`}</th>
                                )
                            })}
                            {/* {((authUser.roleId == 1) ? ((ssForm == 723) ? TableHeading3 : TableHeading2) : TableHeading1).map((item, index) => {
                                return (
                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }} key={index} scope="col">{`${item.label}`}</th>
                                )
                            })} */}
                        </tr>
                    </thead>
                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>

                        {
                            filteredData.map((item, index) => {
                                return (
                                    <tr key={index} className="">
                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{
                                            prizeFor.map(e => {
                                                if (item.prizeFor == e.id) {
                                                    return e.name
                                                }
                                            })
                                        }</td>
                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{item.classNameMr}</td>
                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.subjectNameMr}</td>
                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{
                                            gender.map(e => {
                                                if (item.genderId == e.id) {
                                                    return e.name
                                                }
                                            })
                                        }</td>
                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.amount}</td>
                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.status}</td>

                                        {/* {(() => {
                                            if (authUser.roleId == 1 && ssForm == 722) {
                                                return ( */}
                                        <ApprovalStatusSelectBox approvalStatus={approvalStatus}
                                            initialText={item.allocationStatus}
                                            initialText1={item.status}
                                            ssForm={ssForm}
                                            onChange={(value) => {
                                                let updatedData = filteredData;
                                                updatedData[index] = ({ ...item, allocationStatus: value })
                                                setFilteredData(updatedData);
                                                setData(updatedData);
                                                saveApprovalStatus(value, item.etranNo, item.prizeCode)
                                                setApprove(value);
                                                // setRegNum(updatedData[index].regCode)
                                            }}
                                        />
                                        {/* )
                                            }
                                        })()} */}

                                        {/* disabled={(item.allocationStatus == 1 || approve == 1) ? true : false} */}
                                        {/* disabled={(authUser.branchId == 50) ? false : ((ssForm == 723) ? ((item.allocationStatus == 0) ? false : true) : false)} */}
                                        <td style={{ width: "200px" }}>
                                            <button type="button" className='btn btn-sm btn-primary' disabled={(authUser.branchId == 50) ? false : ((item.allocationStatus == 0) ? false : true)} onClick={() => (ssForm == 724) ?
                                                navigate(`/Home/et722scf/${item.prizeCode}/${item.etranNo}/${item.prizeFor}/${ssForm}`, {
                                                    state: { paritoshikName, pId, yojanaInfo }
                                                })
                                                //navigate(`/Home/et722scf/${item.prizeCode}/${item.etranNo}/${item.prizeFor}/${ssForm}`)
                                                : navigate(`/Home/et722cf/${item.prizeCode}/${item.etranNo}/${item.prizeFor}/${ssForm}`, {
                                                    state: { paritoshikName, pId, yojanaInfo }
                                                })}>
                                                {(()=>{
                                                    if(item.prizeFor == 1){
                                                        return "Add Student"
                                                    }
                                                    else if(item.prizeFor == 2){
                                                        return "Add Employee"
                                                    }
                                                    else if(item.prizeFor == 3){
                                                        return "Add Institute"
                                                    }
                                                })()}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                    <tfoot style={{ padding: "0px" }}>
                        <tr>
                            {/* <TablePagination
                      style={{ padding: "0px" }}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onRowsPerPageChange={onRowsPerPageChange}
                      onPageChange={onPageChange}
                      labelRowsPerPage="Rows Per Page :"
                      classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                      nextIconButtonProps={{ "aria-label": "Next Page" }} /> */}
                        </tr>
                    </tfoot>
                </table>
            </div >

            <div className="row mt-3">
                <div className="col-sm-4">
                    <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722/${ssForm}`)}>Cancel</button>
                </div>
            </div>

        </>
    )
}

export default ConditionDetailsTable
